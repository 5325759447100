import Button from '@/components/Button'
import { toLoginAuthUrl, toSignUpAuthUrl } from '@/lib/authUrl'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import * as styles from './style'

const AuthMenu = (): JSX.Element => {
  const { t } = useTranslation('common')
  const [loginUrl, setLoginUrl] = useState('')
  const [signUpUrl, setSignUpUrl] = useState('')
  const router = useRouter()

  useEffect(() => {
    setLoginUrl(toLoginAuthUrl(router.asPath))
    setSignUpUrl(toSignUpAuthUrl(router.asPath))
  }, [router.asPath])

  return (
    <div className={styles.root}>
      <a data-testid="menu-link-login" href={loginUrl} className={styles.link} title={t('nav.logIn')}>
        {t('nav.logIn')}
      </a>
      <Button theme="secondary" component="a" label={t('nav.signUp')} href={signUpUrl} size="sm" />
    </div>
  )
}

export default AuthMenu

import Layout, { Stack } from '@/components/Layout'
import Text from '@/components/Text'
import { titleCase } from '@/lib/string'
import classNames from 'classnames/bind'
import React from 'react'
import * as styles from './style'
const cx = classNames.bind(styles)

export type Theme = 'primary' | 'secondary' | 'tertiary' | 'tertiary70' | 'white' | 'black' | 'clear' | 'primaryNext'

export interface Props {
  theme?: Theme
  title?: string
  subtitle?: string
  iconRight?: JSX.Element
  avatar?: React.ReactNode | React.ReactNode[]
  href?: string
  smooth?: boolean
  onClick?: () => void
}

const UserCardNext = React.forwardRef(
  ({ theme = 'clear', title, subtitle, iconRight, avatar, href, smooth, onClick }: Props, ref): JSX.Element => {
    const classes = cx({
      root: true,
      [styles[`${theme}`]]: true,
      smooth,
    })
    return (
      <a onClick={onClick} href={href}>
        <Layout className={classes}>
          <Stack x alignItems="center" className={styles.content}>
            {avatar ? <div className="avatar mr-xxxs">{avatar}</div> : null}
            {title ? (
              <Text size="xs" tag="span" className={styles.title}>
                {titleCase(title)}
              </Text>
            ) : null}
            {subtitle ? (
              <Text tag="span" size="xxs" weight="sm">
                {subtitle}
              </Text>
            ) : null}
            {iconRight ? iconRight : null}
          </Stack>
        </Layout>
      </a>
    )
  },
)
export default UserCardNext

import AfterPageLoadComplete from '@/components/AfterPageLoadComplete'
import { rbp } from '@/constants/measured-scope'
import { useAuth } from '@/context/auth'
import { useNavigation } from '@/context/navigation'
import { useUI } from '@/context/ui'
import { useMediaQuery } from '@react-hookz/web'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { useEffect } from 'react'

// workaround instead of using @ts-ignore
declare global {
  interface Window {
    zE: any
  }
}

const ZendeskLocale: Record<string, string> = {
  'en-US': 'en-US',
  'hi-IN': 'hi-IN',
  'zh-CN': 'zh-CN',
  'es-US': 'es',
}

const ZendeskMessagingWidget = (): JSX.Element => {
  const router = useRouter()
  const routerLocale = router.locale ?? 'en-US'
  const zendeskLocale = ZendeskLocale[routerLocale] ?? 'en-US'

  const {
    state: { user },
  } = useAuth()

  const isSmallDevice = useMediaQuery(`only screen and (max-width : ${rbp.tablet})`, { initializeWithValue: false })
  const {
    state: { searchBox },
  } = useNavigation()

  const {
    state: { isZendeskWidgetVisible },
  } = useUI()

  useEffect(() => {
    if (!isSmallDevice || !window.zE) return
    if (searchBox.open || !isZendeskWidgetVisible) {
      window.zE('webWidget', 'hide')
    } else {
      window.zE('webWidget', 'show')
    }
  }, [searchBox.open, isZendeskWidgetVisible])

  useEffect(() => {
    if (!user || !window.zE) return
    window.zE('webWidget', 'prefill', {
      name: {
        value: `${user.firstName} ${user.lastName}`,
        readOnly: true,
      },
      email: {
        value: user.email,
        readOnly: false,
      },
    })
    window.zE('webWidget', 'identify', {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
    })
  }, [user])

  useEffect(() => {
    if (!window.zE) return
    window.zE('webWidget', 'setLocale', zendeskLocale)
  }, [zendeskLocale, window.zE])

  return (
    <AfterPageLoadComplete wait={10000}>
      <Script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=ffef82e5-4409-4975-b4fe-bae0af5e8b14"
        async
        defer
        strategy="lazyOnload"
        onLoad={() => {
          window.zE('webWidget', 'updateSettings', {
            webWidget: {
              offset: {
                mobile: {
                  horizontal: '0px',
                  vertical: '56px',
                },
              },
            },
          })
        }}
      />
    </AfterPageLoadComplete>
  )
}

export default ZendeskMessagingWidget

export const root = "rl7fn07";
export const primary = "p1kq1y4p";
export const secondary = "s1lpa0g";
export const tertiary70 = "tcxkj8d";
export const tertiary = "t1k8ftsm";
export const white = "w1vkd1gz";
export const black = "b1g7ifik";
export const clear = "c1rvvh2w";
export const primaryNext = "p1s5cpje";
export const title = "ttbwiv3";
export const content = "cy05jlh";
export const smooth = "sd8vygs";
export const padding = "p18r217n";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");
import Button from '@/components/Button'
import Layout, { Stack } from '@/components/Layout'
import Text, { Title } from '@/components/Text'
import Lottie from 'lottie-web'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import * as styles from './styles'

export interface Props {
  onClickAddToCalendar?: () => void
  onClickInviteFriend?: () => void
}

const successImageLottieJsonUrl = 'https://ddq2bomue87t4.cloudfront.net/dialog-icons/success.json'

/**
 * This widget is used to provide feedback to a learner when they have successfully booked a single session of a single class.
 */
const BookingSuccess = ({ onClickAddToCalendar, onClickInviteFriend }: Props): JSX.Element => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (ref) {
      Lottie.loadAnimation({
        name: 'dialog-animation',
        container: ref,
        renderer: 'canvas',
        loop: false,
        autoplay: true,
        path: successImageLottieJsonUrl,
      })
    }

    return () => {
      if (ref) {
        Lottie.destroy('dialog-animation')
      }
    }
  }, [ref])

  const { t } = useTranslation('classes')
  return (
    <Layout>
      <Stack y className="pt-lg" alignItems="center">
        <div ref={(newRef) => setRef(newRef)} className={styles.heroAnimation} />
        <Title weight="lg" size="md" className="ta-c pt-lg" responsiveSize={{ sm: 'lg' }}>
          {t('bookingSuccess.title')}
        </Title>
        <Text size="xs" weight="sm" className="ta-c pt-xxxs">
          {t('bookingSuccess.addToCalenderMessage')}
        </Text>
        <Button
          theme="primary"
          label={t('bookingSuccess.addToCalendarLabel')}
          iconLeft="calendar"
          size="md"
          iconSize={24}
          className="mt-lg"
          onClick={onClickAddToCalendar}
        />
        <Button
          theme="link"
          label={t('bookingSuccess.inviteFriendsLabel')}
          size="lg"
          className="fs-xs fw-bold"
          onClick={onClickInviteFriend}
        />
      </Stack>
    </Layout>
  )
}
export default BookingSuccess

// To hold menu & header in tandem, grouped and tethered to the page
export const wrapper = "w12lm4rp";
export const tethered = "tbee430";
export const menuVisible = "m10ghzzu";
export const root = "r1fbrcds";
export const headerContent = "hrpmgbb";
export const roundBottom = "r1afk3n6";
export const logo = "l1caaylt";
export const phone = "pr6sbvj";
export const endSection = "e1jse58e";
export const avatar = "a6kes08";
export const chevron = "c1hhenty";
export const userPopout = "u1ihefe2";
export const pageTitle = "p14j1os5";
export const backButton = "bdh3ud2";
export const menu = "m159pibi";
export const menuItemContainer = "mt3jsvo";

/*
  We use grid to move the header items around (logo, searchInput, contactCTA)
  In the media query( mobile or tablet specific), we build the grid to occupy two rows and two columns
  grid-template-columns: 1fr 2fr; -> Gives us two columns of width ratio 1:2 on the X axis.
  grid-template-rows: repeat(2, 1fr); -> Gives us two rows of width ratio 1:2 on the Y axis.

  Default (on desktop) we have just one row, therefore we only add column properties:
  grid-template-columns: 1fr 8fr 2fr; -> Gives us three columns of width ratio 1:8:2 on the X axis.

  This allows us to move the search input from the first row on desktop to the second row on mobile.
  The styles to move the search input is applied on const searchContainer below.

  In addition, we add fallback styles using flex for browsers that do not support grid.
*/

export const itemsContainer = "i1szc4ub";
/*
  We have grid applied to the parent div, we can move the search input
  to the second row for mobile devices.
  the styles in media query tell the search input to occupy all 3 columns in row 2.
*/

export const searchContainer = "s1357cqm";
export const navMenu = "n1olmuss";
export const searchForm = "sucsaa6";
export const searchMenu = "s1bh08rw";
export const searchSuggestion = "sfxctca";
export const customMenu = "c1fxer3s";
export const phoneNumber = "p16tfmpf";
"slbf3rt";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");
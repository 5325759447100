import { useFeatureToggle } from '@/context/featureToggles'
import React from 'react'
import * as styles from './style'

export interface Props {
  image: string
  withHeading?: boolean
  right?: boolean
}

const SponsorLogo = ({ image, withHeading = true, right = true }: Props): JSX.Element => {
  const enableSponsorship = useFeatureToggle('exp_sponsorships')

  return enableSponsorship ? (
    <div className={`${styles.root} ${withHeading ? styles.shadow : ''}`}>
      {(withHeading || right) && <div className={styles.textContainer}>{withHeading && <span>Sponsored By</span>}</div>}
      <div
        className={`${styles.imageContainer} ${
          withHeading || right ? styles.imageRightBorderRadius : styles.imageLeftBorderRadius
        }`}
      >
        <img src={image} className={styles.image} />
        {withHeading || right ? (
          <div className={styles.outerCircleLeft}></div>
        ) : (
          <>
            <div className={styles.outerCircleRight}></div>
            <div className={styles.outerCircleTop}></div>
          </>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}
export default SponsorLogo

export const imageContainer = "i1gnn00e";
export const image = "ifk55f2";
export const root = "r6uuc5h";
export const verticalSpacing = "v1jouyx";
export const infoContainer = "iop2pss";
export const guideAvatar = "gfdbwd8";
export const guideByline = "g16sl2q5";
export const title = "tl3krzz";
export const liveContainer = "l1xefbsx";
export const liveText = "lkd2au6";
export const dateContainer = "do8bocg";
export const footer = "f1jx6dfp";
export const header = "h132ha6j";
export const badges = "b1jr8vbu";
export const badgesSideView = "bbsbg9v";
export const iconImage = "i1k1qno";
export const overlay = "oi8h7l5";
export const formatLiveTime = "fl3t772";
export const inline = "inztwr0";
export const guideContainer = "g1cbxsjo";
export const live = "l7glw3s";
export const dateOnSide = "dru7upn";
export const dateOnSideDisplay = "d147xlf3";
export const hide = "h3txiow";
export const actions = "a1fq29ne";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");
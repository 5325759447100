import IcoMoon from '@/components/IcoMoon'
import classNames from 'classnames/bind'
import Link from 'next/link'
import * as styles from './style'

const cx = classNames.bind(styles)

export interface Props {
  className?: string
  hasPopOut?: boolean
  label: string
  url?: string
  openInNewTab: boolean
  path?: string
}

const MenuLink = ({ className = '', hasPopOut = false, label, url, openInNewTab, path }: Props): JSX.Element => {
  const classes = cx({
    root: true,
    link: true,
    [`${className}`]: true,
  })

  return (
    <>
      {path ? (
        <Link className={classes} href={path}>
          {label}
        </Link>
      ) : (
        <a
          data-testid="external-link"
          href={url}
          className={classes}
          title={label}
          target={openInNewTab ? '_blank' : '_self'}
        >
          <span>{label}</span>
          {hasPopOut && (
            <span className={styles.hasPopOut}>
              <IcoMoon icon="popout" size={10} />
            </span>
          )}
        </a>
      )}
    </>
  )
}
export default MenuLink

import React from 'react'

export interface Props {
  classIsNew?: boolean
  className?: string
}

const NewClassBadge = ({
  classIsNew,
  className,
}: Props): JSX.Element => // eslint-disable-next-line @next/next/no-img-element
  classIsNew ? <img src={'/images/badge-new.svg'} className={className} /> : <></>
export default NewClassBadge

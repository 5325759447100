import React, { useEffect, useState } from 'react'

type Props = {
  children: React.ReactNode
  wait?: number
}

const AfterPageLoadComplete = ({ children, wait = 1000 }: Props): JSX.Element | null => {
  const [isPageLoadComplete, setPageLoadComplete] = useState(false)
  const [isWaitTimeOver, setWaitTimeOver] = useState(false)

  useEffect(() => {
    if (!isPageLoadComplete) return
    const timer = setTimeout(() => {
      setWaitTimeOver(true)
    }, wait)
    return () => clearTimeout(timer)
  }, [isPageLoadComplete])

  useEffect(() => {
    const onPageLoadComplete = (): void => {
      setPageLoadComplete(true)
    }

    // Check if the page has already loaded
    if (document.readyState === 'complete') {
      onPageLoadComplete()
    } else {
      window.addEventListener('load', onPageLoadComplete)
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', onPageLoadComplete)
    }
  }, [])

  return isWaitTimeOver ? <>{children}</> : null
}
export default AfterPageLoadComplete

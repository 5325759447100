import Button from '@/components/Button'
import Layout, { Stack } from '@/components/Layout'
import Text from '@/components/Text'
import { useTranslation } from 'next-i18next'
import { useEffect } from 'react'

export interface Props {
  onDismiss?: () => void
}

const InviteFriend = ({ onDismiss }: Props): JSX.Element => {
  useEffect(() => {
    try {
      setTimeout(() => {
        const el = document.createElement('script')
        el.setAttribute('id', 'addthis-share')
        el.setAttribute('src', `//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-6059a81e8d2295b1`)
        document.body.appendChild(el)
      })
    } catch (e) {
      console.log('ADDTHIS_EXCEPTION:', e)
    }
  }, [])
  const { t } = useTranslation('classes')
  return (
    <Layout spacing="sm">
      <Stack y>
        <Button iconLeft="arrow-left" size="sm" theme="primaryMuted" onClick={onDismiss} />
        <Text>{t('inviteFriends.title')}</Text>
        <Text size="xxs" weight="sm">
          {t('inviteFriends.message')}
        </Text>
        <Text size="sm">{t('inviteFriends.shareClass')}</Text>
        <div className="addthis_inline_share_toolbox" />
      </Stack>
    </Layout>
  )
}
export default InviteFriend

import { CalendarUrl } from '@/lib/calendarUrl'
import AddToCalendar from '@/widgets/AddToCalendar'
import BookingSuccess from '@/widgets/BookingSuccess'
import InviteFriend from '@/widgets/InviteFriend'
import Modal from '@/widgets/Modal'
import { useState } from 'react'
interface Props {
  toggleBookingSuccess: () => void
  calendarUrl: CalendarUrl
}

const BookingSuccessModal = ({ toggleBookingSuccess, calendarUrl }: Props): JSX.Element => {
  const [openAddtoCalendar, setOpenAddtoCalendar] = useState<boolean>(false)
  const [openInviteFriend, setOpenInviteFriend] = useState<boolean>(false)

  return (
    <Modal onDismiss={toggleBookingSuccess}>
      {openAddtoCalendar ? (
        <AddToCalendar
          onDismiss={() => setOpenAddtoCalendar(false)}
          googleCalendarUrl={calendarUrl.googleCalendarUrl}
          yahooCalendarUrl={calendarUrl.yahooCalendarUrl}
          outlookCalendarUrl={calendarUrl.outlookCalendarUrl}
          iCalUrl={calendarUrl.iCalUrl}
        />
      ) : openInviteFriend ? (
        <InviteFriend onDismiss={() => setOpenInviteFriend(false)} />
      ) : (
        <BookingSuccess
          onClickAddToCalendar={() => setOpenAddtoCalendar(true)}
          onClickInviteFriend={() => setOpenInviteFriend(true)}
        />
      )}
    </Modal>
  )
}
export default BookingSuccessModal

import { getConfig } from '@/lib/config'
import * as Sentry from '@sentry/nextjs'

import { Request } from '@/service/request'

const {
  publicRuntimeConfig: {
    graphqlApi: { url },
  },
} = getConfig()

interface PartnerCookie {
  id: string
  code: string
  name: string
  slug: string
}

const PARTNER_COOKIE_NAME = 'auth.partner'
const PARTNER_COOKIE_FIELDS: (keyof PartnerCookie)[] = ['id', 'code', 'name', 'slug']

export function getPartnerCookie(req: Request): PartnerCookie | null {
  const unparsedCookie = req.cookies && req.cookies[PARTNER_COOKIE_NAME]

  if (!unparsedCookie) return null

  try {
    const cookie: PartnerCookie = JSON.parse(unparsedCookie)
    if (
      !PARTNER_COOKIE_FIELDS.every((field) => {
        return cookie[field]
      })
    ) {
      return null
    }

    return cookie
  } catch (e) {
    return null
  }
}

// Function to set partner cookies from any route in case UTM source is provided
export async function setPartnerCookieByCode(code?: string | string[]): Promise<void> {
  if (!code || typeof code !== 'string') return
  try {
    const requestBody = {
      query: `
        mutation setPartnerCookieByCode($code: String!) {
          setPartnerCookieByCode(code:$code) { __typename }
        }
        `,
      variables: {
        code,
      },
    }
    const response: any = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(requestBody),
    })
    const parsedResponse = await response.json()
    if (parsedResponse.errors) throw new Error(JSON.stringify(parsedResponse.errors))
  } catch (err) {
    // Log this error to sentry but dont panic.
    console.error('Failed to do set partner from gsu-web', err)
    Sentry.captureException(err)
  }
}

import Layout, { Stack } from '@/components/Layout'
import MenuLinkInternal from '@/components/MenuLinkInternal'
import MenuLink from '@/components/MenuLinks'
import { rbp } from '@/constants/measured-scope'
import SearchBox from '@/widgets/SearchBox'
import { useMediaQuery } from '@react-hookz/web'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import * as styles from './style'

interface NavigationLink {
  active?: boolean
  url: string
  label: string
  isExternal?: boolean
  shouldBeAuthenticatedUser?: boolean
  hideOnMobile?: boolean
  isBeta?: boolean
  isNew?: boolean
}

const SecondaryNavigation = (): JSX.Element => {
  const router = useRouter()
  const isLargeDevice = useMediaQuery(`only screen and (min-width : ${rbp.lg})`, { initializeWithValue: false })
  const isActive = useCallback(
    (url: string): boolean => {
      if (!router.isReady) return false
      if (url === '/' && router.pathname === '/partner/[slug]') return true
      if (router.asPath === '/series/socialconnections') {
        return url === '/series/socialconnections' // Community button should be active when on community page
      }
      if (url === '/' && router.pathname === '/exp-home') return true
      if (url === '/' && router.pathname !== '/') return false
      return router.asPath === url || router.asPath.startsWith(`${url}/`)
    },
    [router.isReady, router.asPath],
  )

  function MenuLinks(): NavigationLink[] {
    const { t } = useTranslation('common')
    const menuLinks: NavigationLink[] = [
      { label: t('nav.home'), url: '/', hideOnMobile: true },
      { label: t('nav.calendar'), url: '/schedule/browse' },
      { label: t('nav.mySchedule'), url: '/schedule/my-schedule' },
      { label: t('nav.videos'), url: '/videos', isBeta: true },
      { label: t('nav.articles'), url: '/articles', isBeta: true },
      { label: t('nav.series'), url: '/series' },
      { label: t('search.guides'), url: '/guides' },
      { label: t('nav.community'), url: '/series/socialconnections' },
      { label: t('nav.medicare'), url: '/medicare', isNew: true },
    ]
    return menuLinks
  }

  const menuLinks = MenuLinks()

  return (
    <Layout className={styles.wrapper}>
      <Stack className={styles.container} x alignItems="flex-start" justifyContent="space-between">
        <Stack className={styles.navigation} x>
          {menuLinks.map((menuLink, i) => {
            if (menuLink.isExternal) {
              return (
                <MenuLink
                  key={`menu-link-external-${i}`}
                  hasPopOut={true}
                  label={menuLink.label}
                  url={menuLink.url}
                  openInNewTab={false}
                />
              )
            } else if (isLargeDevice || !menuLink.hideOnMobile) {
              return (
                <MenuLinkInternal
                  key={`menu-link-internal-${i}`}
                  active={isActive(menuLink.url)}
                  label={menuLink.label}
                  url={menuLink.url}
                  isBeta={menuLink.isBeta}
                  isNew={menuLink.isNew}
                />
              )
            }
          })}
        </Stack>
        {isLargeDevice && (
          <div className={styles.searchContainer}>
            <SearchBox hasResetOption isLargeDevice />
          </div>
        )}
      </Stack>
    </Layout>
  )
}
export default SecondaryNavigation

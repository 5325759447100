export const responsiveSizes = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl'] as const
export type ResponsiveSize = (typeof responsiveSizes)[number]

let rbpValues = {
  xxs: '320px',
  xs: '480px',
  sm: '600px',
  md: '768px',
  lg: '980px',
  xl: '1120px',
  xxl: '1280px',
  xxxl: '1440px',
}

// Ergonomics for referring to devices
// if that's helpful, but we'll always lean
// on the ResponsiveSizes enum
// Values here dictate the min-width of the device
export const rbp = {
  ...rbpValues,
  mobile: rbpValues.xs,
  smallTablet: rbpValues.sm,
  tablet: rbpValues.lg,
  desktop: rbpValues.xxl,
  largeDesktop: rbpValues.xxxl,
}
export type RBP = keyof typeof rbp

export const unitSizes = ['sm', 'md', 'lg'] as const
export type UnitSize = (typeof unitSizes)[number]

// Can be applied to any page section that should have a specific
// rule-set for padding at given sizes
export const coreSectionPaddingClasses = `p-sm px-lg-gte-sm px-xl-gte-lg py-md-gte-lg px-xxl-gte-xxl py-lg-gte-xxl`
export const coreSectionPaddingClassesTopHalf = `pt-sm pt-md-gte-sm pt-lg-gte-lg pb-xs pb-sm-gte-sm pb-md-gte-lg px-sm px-lg-gte-sm px-xl-gte-lg px-xxl-gte-xxl `
export const coreSectionPaddingClassesBottomHalf = `pb-sm pb-md-gte-sm pb-lg-gte-xxl px-sm px-lg-gte-sm px-xl-gte-lg px-xxl-gte-xxl`
export const coreSectionPaddingClassesLeftOnly = `pl-lg-gte-md pl-xl-gte-md pl-xl-gte-xxl pl-md`
export const coreSectionPaddingClassesRightOnly = `pr-xxl-gte-md pr-md`

// Main houses all page content and provides top and bottom padding to all pages
// Section provides page padding to all sections directly within Main
// Container provides a max width to page content amd sits directly within core sections
// ContainerAside is the same as container, but includes an aside column
// Heading provides padding for the default page heading (Categories, Series, Guides)

export const coreLayoutMain = 'coreLayoutMain pt-sm pt-md-gte-lg pt-lg-gte-xxl pb-lg pb-xl-gte-lg pb-xxl-gte-xxl'
export const coreLayoutSection =
  'coreLayoutSection p-sm px-lg-gte-sm px-xl-gte-lg px-xxl-gte-xxl py-md-gte-lg py-lg-gte-xxl'
export const coreLayoutSectionX = 'coreLayoutSectionX px-sm px-lg-gte-sm px-xl-gte-lg px-xxl-gte-xxl'
export const coreLayoutSectionY = 'coreLayoutSectionY py-sm py-md-gte-lg py-lg-gte-xxl'
export const coreLayoutContainer = 'coreLayoutContainer page-max-width'
export const coreLayoutContainerAside = 'coreLayoutContainerAside gridAside page-max-width'
export const coreLayoutHeading =
  'coreLayoutHeading px-sm px-lg-gte-sm px-xl-gte-lg px-xxl-gte-xxl pt-sm pt-md-gte-lg pt-lg-gte-xxl'

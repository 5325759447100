import SearchBox from '@/widgets/SearchBox'
import { CSSTransition } from 'react-transition-group'
import * as styles from './style'

export interface Props {
  menuVisible: boolean
}

const SearchMenu = ({ menuVisible }: Props): JSX.Element => {
  return (
    <CSSTransition in={menuVisible} timeout={300} unmountOnExit classNames="searchBox">
      <nav className={styles.menuWrapper}>
        <SearchBox isSmallDevice />
      </nav>
    </CSSTransition>
  )
}

export default SearchMenu

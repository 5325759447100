import React from 'react'
import * as styles from './style'
import classNames from 'classnames/bind'
const cx = classNames.bind(styles)

export interface Props {
  children: React.ReactNode | React.ReactNode[]
  theme?: 'urgent'
  clearBg?: boolean
  className?: string
}

const StatusBadge = ({ children, theme = 'urgent', clearBg, className = '' }: Props): JSX.Element => {
  const classes = cx({
    root: true,
    [styles[`${theme}`]]: true,
    clearBg,
    [className]: true,
  })

  return <span className={classes}>{children}</span>
}
export default StatusBadge

export const root = "ren0qfn";
export const imageWrapper = "i1n611sl";
export const image = "i1sr70f7";
export const truncate = "t1tboq95";
export const text = "tkm7qg2";
export const subtitle = "seywtbr";
export const guidePhoto = "gl2l0ly";
export const badges = "bsxn0ek";
export const roundBadges = "r17zg2im";
export const newBadge = "noid1f5";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");
import Button from '@/components/Button'
import { toLoginAuthUrl } from '@/lib/authUrl'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

const AuthCTA: React.FC = () => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const [authUrl, setAuthUrl] = useState('')

  useEffect(() => {
    setAuthUrl(toLoginAuthUrl(router.asPath))
  }, [router.asPath])

  return (
    <div className="hide-lte-desktop">
      <Button className="maxh-48 fs-xs" theme="primary" component="a" label={t('authCTA')} href={authUrl} />
    </div>
  )
}

export default AuthCTA

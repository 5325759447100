import { getConfig } from '@/lib/config'
import { getDateTimeSeconds, getDateTimeString } from '@/lib/datetime'
import { lobbyUrl } from '@/lib/lobbyUrl'
import { kebabCase } from '@/lib/string'
import { SessionCard } from '@/shared/api-generated-types'
import * as Sentry from '@sentry/nextjs'

const {
  publicRuntimeConfig: {
    webapi: { url: API_HOST },
  },
} = getConfig()

export interface Props {
  eventStart: string
  eventEnd: string
  title: string
  apiUrl?: string
  slug: string
  sessionId: string
}

export interface CalendarUrl {
  googleCalendarUrl: string | undefined
  yahooCalendarUrl: string | undefined
  outlookCalendarUrl: string | undefined
  iCalUrl: string | undefined
}

export const googleCalendarEventURL = ({ eventStart, eventEnd, sessionId, title }: Props): string | undefined => {
  try {
    const searchParams = {
      action: 'TEMPLATE',
      dates: `${eventStart}/${eventEnd}`,
      details: lobbyUrl(`/session/${sessionId}`),
      location: lobbyUrl(`/session/${sessionId}`),
      text: title,
    }
    const urlSearchParams = new URLSearchParams(searchParams)
    return 'https://www.google.com/calendar/render?' + urlSearchParams.toString()
  } catch (error) {
    Sentry.captureException(error)
    return undefined
  }
}

export const iCalEventURL = ({ eventStart, eventEnd, slug, sessionId, title, apiUrl }: Props): string => {
  const searchParams = {
    sessionId,
    start: eventStart,
    end: eventEnd,
    title,
    slug,
    url: lobbyUrl(),
  }
  const urlSearchParams = new URLSearchParams(searchParams)
  return `${apiUrl}/api/auth/ical?${urlSearchParams.toString()}`
}
export const outlookCalendarEventURL = ({ eventStart, eventEnd, sessionId, title }: Props): string | undefined => {
  try {
    const searchParams = {
      path: '/calendar/action/compose',
      rru: 'addevent',
      enddt: eventEnd,
      startdt: eventStart,
      subject: title,
      location: lobbyUrl(`/session/${sessionId}`),
      v: '60',
    }
    const urlSearchParams = new URLSearchParams(searchParams)
    return 'https://outlook.live.com/calendar/0/deeplink/compose?' + urlSearchParams.toString()
  } catch (error) {
    Sentry.captureException(error)
    return undefined
  }
}
export const yahooCalendarEventURL = ({ eventStart, eventEnd, title, sessionId }: Props): string | undefined => {
  try {
    const searchParams = {
      et: eventEnd,
      st: eventStart,
      title: title,
      in_loc: lobbyUrl(`/session/${sessionId}`),
      desc: lobbyUrl(`/session/${sessionId}`),
      v: '60',
    }
    const urlSearchParams = new URLSearchParams(searchParams)
    return 'https://calendar.yahoo.com?' + urlSearchParams.toString()
  } catch (error) {
    Sentry.captureException(error)
    return undefined
  }
}

export const createCalendarUrl = (session: Partial<SessionCard>): CalendarUrl => {
  const { start, end, title, sessionId } = session
  const eventStart = getDateTimeString(start ? start : '')
  const eventEnd = getDateTimeString(end ? end : '')
  const slug = kebabCase(title ? title : '')
  const eventStartMiliseconds = getDateTimeSeconds(start)
  const eventEndMiliseconds = getDateTimeSeconds(end)
  const baseOpts = {
    eventEnd: eventEnd,
    sessionId: sessionId ? sessionId : '',
    eventStart: eventStart,
    slug: slug,
    title: title ? title : '',
  }

  const googleCalendarUrl = googleCalendarEventURL(baseOpts)
  const iCalUrl = iCalEventURL({
    slug: slug,
    title: title ? title : '',
    eventStart: eventStartMiliseconds,
    eventEnd: eventEndMiliseconds,
    apiUrl: API_HOST,
    sessionId: sessionId ? sessionId : '',
  })
  const yahooCalendarUrl = yahooCalendarEventURL(baseOpts)
  const outlookCalendarUrl = outlookCalendarEventURL(baseOpts)

  return {
    googleCalendarUrl: googleCalendarUrl,
    yahooCalendarUrl: yahooCalendarUrl,
    outlookCalendarUrl: outlookCalendarUrl,
    iCalUrl: iCalUrl,
  }
}

import { Stack } from '@/components/Layout'
import Text from '@/components/Text'
import { coreLayoutContainer, coreLayoutSection, coreLayoutSectionY, rbp } from '@/constants/measured-scope'
import { useUI } from '@/context/ui'
import { useMediaQuery } from '@react-hookz/web'
import React, { useEffect, useRef } from 'react'
import * as styles from './style'

export interface Props {
  links?: JSX.Element[]
  copyrightText?: string
  policyLinks?: JSX.Element[]
}

const currentYear = new Date().getUTCFullYear()

const PageFooter = ({ links, copyrightText, policyLinks }: Props): JSX.Element => {
  const footerRef = useRef<HTMLDivElement | null>(null)

  const { dispatch } = useUI()
  const isSmallDevice = useMediaQuery(`only screen and (max-width : ${rbp.smallTablet})`, {
    initializeWithValue: false,
  })
  const imageSize = {
    height: isSmallDevice ? 40 : 56,
    width: isSmallDevice ? 117 : 164,
  }

  useEffect(() => {
    setTimeout(() => {
      if (!footerRef.current) return
      const hasIOSupport = !!window.IntersectionObserver
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0]?.isIntersecting) {
            dispatch({
              type: 'SET_IS_FOOTER_VISIBLE',
              payload: {
                isVisible: true,
              },
            })
          } else {
            dispatch({
              type: 'SET_IS_FOOTER_VISIBLE',
              payload: {
                isVisible: false,
              },
            })
          }
        },
        {
          threshold: 0.5,
        },
      )
      observer.observe(footerRef?.current)
      if (!hasIOSupport) return
    }, 400)
  }, [footerRef])

  return (
    <footer ref={footerRef}>
      <div className={`${styles.root} pageFooter`}>
        <div className={coreLayoutSectionY}>
          <Stack y className={coreLayoutSection} spaceBetweenY="lg" justifyContent="center" alignItems="center">
            <img src="/images/logo-getsetup-light.svg" alt="GetSetUp logo" style={imageSize} loading="lazy" />
            <div className={`${coreLayoutContainer} mt-md`}>
              {links?.length && (
                <Text className="flex-grid" size="xs" weight="sm">
                  {links.map((link, i) => (
                    <span key={i} className="d-flex mb-xs">
                      {link}
                    </span>
                  ))}
                </Text>
              )}
            </div>
          </Stack>
        </div>
        <Text tag="div" size="xxs" weight="sm" className="footerLinks">
          {policyLinks?.length ? (
            <div>
              {policyLinks?.map((link, i) => {
                return (
                  <React.Fragment key={i}>
                    {link}
                    {i !== policyLinks.length - 1 ? ' ' + '·' + ' ' : ''}
                  </React.Fragment>
                )
              })}
            </div>
          ) : null}
          <div className="fc-primary-30">
            &copy; {currentYear} {copyrightText}
          </div>
        </Text>
      </div>
    </footer>
  )
}

export default PageFooter

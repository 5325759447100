import Layout, { Stack } from '@/components/Layout'
import Text from '@/components/Text'
import { titleCase } from '@/lib/string'
import classNames from 'classnames/bind'
import Link from 'next/link'
import React from 'react'
import * as styles from './style'
const cx = classNames.bind(styles)

export type Theme = 'primary' | 'secondary' | 'tertiary' | 'tertiary70' | 'white' | 'black' | 'clear' | 'primaryNext'

export interface Props {
  theme?: Theme
  title?: string
  subtitle?: string
  iconRight?: JSX.Element
  avatar?: React.ReactNode | React.ReactNode[]
  href?: string
  smooth?: boolean
  onClick?: () => void
}

const UserCard = React.forwardRef(
  ({ theme = 'clear', title, subtitle, iconRight, avatar, href, smooth, onClick }: Props, ref): JSX.Element => {
    const classes = cx({
      root: true,
      [styles[`${theme}`]]: true,
      smooth,
    })
    return (
      <Link href={href ?? '#'} passHref legacyBehavior>
        <a role="link" onClick={onClick}>
          <Layout className={`${classes} p-xxs`}>
            <Stack x alignItems="center" className="pr-xs">
              {avatar ? <div className="avatar pr-sm">{avatar}</div> : null}
              <Stack y alignSelf="center" className={styles.content}>
                {title ? (
                  <Text size="xs" tag="span" className={styles.title}>
                    {titleCase(title)}
                  </Text>
                ) : null}
                {subtitle ? (
                  <Text tag="span" size="xxs" weight="sm">
                    {subtitle}
                  </Text>
                ) : null}
              </Stack>
              {iconRight ? iconRight : null}
            </Stack>
          </Layout>
        </a>
      </Link>
    )
  },
)
export default UserCard

import IcoMoon, { IconNames } from '@/components/IcoMoon'
import Text from '@/components/Text'
import classNames from 'classnames/bind'
import { useTranslation } from 'next-i18next'
import { animated, config, useSpring } from 'react-spring'
import * as styles from './style'

const cx = classNames.bind(styles)

export type BadgeSize = 'XS' | 'SM' | 'MD' | 'LG'
export type BadgeTheme = 'MINIMAL' | 'FULL' | 'FULL_ON_FOCUS'
export type BadgeType = 'CLASS_NOTES' | 'COMMUNITY' | 'ENCORE' | 'ON_DEMAND' | 'VIDEO'

type SizeParams = {
  iconSize: number
  sizeClass: string
}
type TypeParams = {
  bgClass: string
  icon: IconNames
  label: string
}
type BadgeParams = SizeParams & TypeParams

function getSizeParams(size: BadgeSize): SizeParams {
  switch (size) {
    case 'XS':
      return {
        iconSize: 12,
        sizeClass: styles.sizeXS,
      }

    case 'SM':
      return {
        iconSize: 14,
        sizeClass: styles.sizeSM,
      }

    case 'MD':
      return {
        iconSize: 20,
        sizeClass: styles.sizeMD,
      }

    case 'LG':
      return {
        iconSize: 24,
        sizeClass: styles.sizeLG,
      }
  }
}

export interface Props {
  compactBadges?: boolean
  inFocus?: boolean
  size: BadgeSize
  theme: BadgeTheme
  type: BadgeType
  rounded?: boolean
}

const Badge = ({ compactBadges = false, inFocus = false, size, theme, type, rounded }: Props): JSX.Element => {
  const { t } = useTranslation('badge')

  function getTypeParams(type: BadgeType): TypeParams {
    switch (type) {
      case 'CLASS_NOTES':
        return {
          bgClass: styles.classNotes,
          icon: 'notes',
          label: t('labels.classNotes'),
        }

      case 'COMMUNITY':
        return {
          bgClass: styles.community,
          icon: 'community',
          label: t('labels.community'),
        }

      case 'ENCORE':
        return {
          bgClass: styles.encore,
          icon: 'encore',
          label: t('labels.encore'),
        }

      case 'ON_DEMAND':
        return {
          bgClass: styles.onDemand,
          icon: 'on-demand',
          label: t('labels.onDemand'),
        }
      case 'VIDEO':
        return {
          bgClass: styles.video,
          icon: 'video',
          label: '',
        }
    }
  }

  // function to determine various badge parameters
  function getBadgeParams(size: BadgeSize, type: BadgeType): BadgeParams {
    const params: BadgeParams = {
      bgClass: getTypeParams(type).bgClass,
      icon: getTypeParams(type).icon,
      iconSize: getSizeParams(size).iconSize,
      label: getTypeParams(type).label,
      sizeClass: getSizeParams(size).sizeClass,
    }
    return params
  }

  const params = getBadgeParams(size, type)

  const props = useSpring({
    from: { width: '0%' },
    to: { width: '100%' },
    reset: false,
    reverse: !inFocus,
    config: config.default,
  })

  const wrapperClass = cx({
    root: true,
    [params.bgClass]: true,
    [params.sizeClass]: true,
    [styles.noBorderRadius]: compactBadges,
    [styles.rounded]: rounded,
  })

  return theme === 'FULL_ON_FOCUS' ? (
    <animated.div
      className={`${styles.root} ${compactBadges ? styles.noBorderRadius : ''} ${params.bgClass} ${params.sizeClass} `}
      style={props}
    >
      <div className={styles.icon}>
        <IcoMoon icon={params.icon} size={params.iconSize} />
      </div>
      <Text className={styles.label}>{params.label}</Text>
    </animated.div>
  ) : (
    <div className={wrapperClass}>
      <div className={`${styles.icon}  `}>
        <IcoMoon icon={params.icon} size={params.iconSize} />
      </div>
      {theme === 'FULL' && <Text className={styles.label}>{params.label}</Text>}
    </div>
  )
}
export default Badge

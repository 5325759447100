import Icon from '@/components/Icon'
import Layout, { Stack } from '@/components/Layout'
import Text from '@/components/Text'
import { SessionCard } from '@/shared/api-generated-types'
import dayjs from 'dayjs'
import Link from 'next/link'
import * as styles from './style'

export interface Props {
  title?: string
  subtitle?: string
  imageUrl?: string
  start?: string
  navigationAction: SessionCard['navigationAction']
  onClick?: () => void
}

const ResultItem = ({ title, subtitle, imageUrl, navigationAction, start, onClick }: Props): JSX.Element => (
  <Link href={navigationAction?.url ?? '/'} className={styles.root} onClick={onClick}>
    <Layout spacing="md">
      <Stack x alignItems="center">
        {imageUrl ? (
          <Stack y className={styles.imageWrapper} alignSelf="center">
            <div className={styles.image}>
              <img src={imageUrl} alt={title} loading="lazy" />
            </div>
          </Stack>
        ) : null}
        <Stack y className={styles.text}>
          {title ? (
            <Text
              responsiveSize={{ lg: 'md', md: 'md', sm: 'xs' }}
              weight="lg"
              tag="strong"
              className={styles.truncate}
            >
              {title}
            </Text>
          ) : null}
          {subtitle ? (
            <Text size="xxs" weight="sm" className={`${styles.truncate} fc-dark-grey`}>
              {subtitle}
            </Text>
          ) : null}
          {start ? (
            <Text size="xs" weight="lg" className="flex-center">
              <Icon className="mr-xxs" name="calendar" size={20} align="middle" />{' '}
              {dayjs(start).format('MMMM DD, hh:mm A')}
            </Text>
          ) : null}
        </Stack>
      </Stack>
    </Layout>
  </Link>
)

export default ResultItem

import { initials } from '@/lib/string'
import classNames from 'classnames/bind'
import * as styles from './style'
const cx = classNames.bind(styles)

export type Size = 'xs' | 'sm' | 'xxsmd' | 'xsmd' | 'md' | 'lg' | 'xl' | 'xxl'

export interface Props {
  src?: string
  outlined?: boolean
  size: Size
  alt?: string
  attention?: boolean
  className?: string
  // Title should always be provided, and will fallback if no image
  title?: string
  border?: boolean
}

const Avatar = ({
  size,
  src,
  outlined,
  attention,
  alt = 'Avatar',
  className = '',
  title,
  border = false,
}: Props): JSX.Element => {
  const classes = cx({
    root: true,
    [className]: true,
    outlined,
    attention,
    border,
    initials: !src,
  })
  return (
    <span className={`${classes} ${size ? styles[`${size}`] : ''}`} data-testid="avatar">
      {src ? <img src={src} alt={`${title} ${alt ?? ''}`} loading="lazy" /> : initials(title ?? '')}
    </span>
  )
}
export default Avatar

import { Stack } from '@/components/Layout'
import Text, { Sizes } from '@/components/Text'
import { LivenessType } from '@/shared/api-generated-types'
import Badges from '@/widgets/Badges'
import Router from 'next/router'
import React from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from '../../lib/gsu-dayjs'
import IcoMoon from '../IcoMoon'
import * as styles from './style'

export interface Props {
  value: string | Date
  multiline?: boolean
  simple?: boolean
  durationOnly?: boolean
  abbreviated?: boolean
  dayWithCalendar?: boolean
  color?: 'dark' | 'grey'
  isCommunityLedClass?: boolean
  isEncore?: boolean
  livenessType?: LivenessType
  end?: string
}

const DateDisplay = ({
  value,
  multiline,
  simple,
  durationOnly,
  abbreviated,
  dayWithCalendar,
  color,
  isCommunityLedClass,
  isEncore,
  livenessType,
  end,
}: Props): React.ReactNode & JSX.Element => {
  const { t } = useTranslation(['common'])
  const router = Router
  const locale = router.locale || 'en'

  const date = dayjs(value)
  const dayName = date.locale(locale).format('dddd')
  const dayTime = date.locale(locale).format('h:mm A')
  const timeEnd = end && dayjs(end).locale(locale).format('h:mm A')
  const dateOrToday = date.locale(locale).isToday() ? t('search.today') : date.locale(locale).format('D MMM')
  const dayDate = date.locale(locale).format('D MMM')
  const dayNameAbbrivated = date.locale(locale).format('ddd')
  const dayTimeLowerCase = date.locale(locale).format('h:mma')
  if (durationOnly) {
    const duration = typeof value === 'string' ? value : ''
    return (
      <div className={styles.date}>
        <IcoMoon icon={'on-demand'} size={16} className="mr-xxxs" />
        <Text size="xxs" weight="sm">
          {duration}
        </Text>
      </div>
    )
  }

  if (simple) return <>{`${dayName} ${dateOrToday}, ${dayTime}`}</>

  if (multiline) {
    return (
      <Text size="md" weight="md" className="fc-dark">
        <Text size="sm" weight="lg" tag="p">
          {dayTime}
        </Text>
        <Text tag="p" weight="sm" size="xs">
          {dateOrToday}
        </Text>
      </Text>
    )
  }

  const textProps = {
    tag: 'span' as keyof JSX.IntrinsicElements,
    size: 'xxs' as Sizes,
  }

  const timeWeight = abbreviated ? 'sm' : 'md'
  const icon = date.isToday() ? 'clock' : 'calendar'
  return (
    <>
      {dayWithCalendar ? (
        <>
          <div className={styles.date}>
            <IcoMoon icon={'calendar'} size={16} className="mr-xxxs" />
            <Text size="xxs" weight="lg">
              {dateOrToday === 'Today' ? null : `${dayNameAbbrivated} `}
              {dateOrToday}
            </Text>
            <Text size="xxs" className={styles.hideOnMobile}>
              , {dayTimeLowerCase}
            </Text>
          </div>
          <Text size="xxs" tag="span" className={styles.hideOnDesktop}>
            {dayTimeLowerCase}
          </Text>
        </>
      ) : (
        <>
          {!abbreviated && (
            <Stack x className={`${styles.container} mb-xxxs-gte-lg`} alignItems="center">
              <Text
                {...textProps}
                weight="lg"
                className={`${color === 'dark' ? 'fc-dark' : 'fc-grey'} fs-xs fs-sm-gte-lg`}
              >
                {dayNameAbbrivated} {dayDate}
              </Text>
              <Badges
                className="ml-sm"
                isCommunityLedClass={isCommunityLedClass}
                isEncore={isEncore}
                isVideo={livenessType === LivenessType.Live}
                size={'XS'}
                rounded
                overlappingBadges
              />
            </Stack>
          )}
          <Text {...textProps} weight={timeWeight} className={`${styles.date} fc-dark`}>
            {abbreviated && <IcoMoon icon={icon} size={24} className="mr-xxs" />}
            {dayTime} {timeEnd ? '- ' + timeEnd : ''}
          </Text>
        </>
      )}
    </>
  )
}

export default DateDisplay

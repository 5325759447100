export const root = "r1s37tlo";
export const noBorderRadius = "nd8kci2";
export const rounded = "ry59mc9";
export const classNotes = "c18sbfts";
export const community = "cqpjrym";
export const encore = "e1g27jk0";
export const video = "vzh5obk";
export const onDemand = "oxullv2";
export const icon = "iolq29c";
export const labelContainer = "la0ukv5";
export const label = "lx8vqvk";
export const sizeXS = "s3jq3n5";
export const sizeSM = "sd9ukdo";
export const sizeMD = "sx23dqs";
export const sizeLG = "sr3ixr1";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");
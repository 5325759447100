import ZendeskMessagingWidget from '@/components/ZendeskMessagingWidget'
import { coreLayoutMain, rbp } from '@/constants/measured-scope'
import { useAuth } from '@/context/auth'
import { getConfig } from '@/lib/config'
import { eventsTracker, UIElementNames } from '@/lib/eventsTracker'
import { setPartnerCookieByCode } from '@/lib/partners'
import { QUERY_APP } from '@/service/query'
import PageContent from '@/widgets/PageContent'
import PageFooter from '@/widgets/PageFooter'
import { Props as HeaderProps, useMenuRef } from '@/widgets/PageHeader'
import PageHeaderNext from '@/widgets/PageHeaderNext'
import { useMediaQuery } from '@react-hookz/web'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo } from 'react'
import { useQuery } from 'urql'

interface Props {
  children?: React.ReactNode | React.ReactNode[]
  footerVisible?: boolean
  headerProps?: Partial<HeaderProps>
  contentClassName?: string
  disablePageContentBottomPadding?: boolean
}

const { publicRuntimeConfig } = getConfig()
const BASE_URL = publicRuntimeConfig.website.url
const WEB_URL = publicRuntimeConfig.website.webUrl

const PageLayout = ({
  children,
  footerVisible = true,
  headerProps = {},
  contentClassName = '',
  disablePageContentBottomPadding,
}: Props): JSX.Element => {
  const { t } = useTranslation('common')
  const router = useRouter()
  const isSmallDevice = useMediaQuery(`only screen and (max-width : ${rbp.smallTablet})`, {
    initializeWithValue: false,
  })
  const { openCustomMenu, closeCustomMenu } = useMenuRef()
  const { dispatch: authDispatch } = useAuth()
  const [{ data }] = useQuery({ query: QUERY_APP, requestPolicy: 'cache-first' })

  // Set Partner Cookie when set via UTM source
  useEffect(() => {
    if (!router.isReady) return
    let partnerCode = null
    for (const param in router.query) {
      if (param.toLowerCase() === 'utm_source') {
        partnerCode = router.query[param]
        break
      }
    }
    if (!partnerCode) return
    setPartnerCookieByCode(partnerCode)
  }, [router.isReady])

  useEffect(() => {
    if (data?.currentUser) {
      authDispatch({ type: 'UPDATE', payload: { user: data.currentUser.authenticatedUser } })
    }
  }, [data?.currentUser])

  useEffect(() => {
    if (!!headerProps?.customMenu) openCustomMenu()
    else closeCustomMenu()
  }, [headerProps?.customMenu])

  const menuLinks = useMemo(() => {
    const links: JSX.Element[] = [
      <a
        href="https://share.hsforms.com/1NendfH52RSCvpOFkCq8D1A49suz"
        onClick={() => eventsTracker.handleOnClickAndTrackEvent(UIElementNames.LINK_SIDEBAR_MENU_HOST_SESSION)}
        target="_blank"
      >
        {t('menu.host')}
      </a>,
      <a
        href="https://www.getsetup.com/about-us/about-us"
        onClick={() => eventsTracker.handleOnClickAndTrackEvent(UIElementNames.LINK_SIDEBAR_MENU_ABOUT_US)}
        target="_blank"
      >
        {t('menu.about')}
      </a>,
      <Link
        prefetch={false}
        href={`${BASE_URL}/pricing`}
        onClick={() => eventsTracker.handleOnClickAndTrackEvent(UIElementNames.LINK_SIDEBAR_MENU_PRICING)}
      >
        {t('menu.pricing')}
      </Link>,
      <a
        href="https://www.getsetup.com/about-us/careers"
        onClick={() => eventsTracker.handleOnClickAndTrackEvent(UIElementNames.LINK_SIDEBAR_MENU_CAREERS)}
        target="_blank"
      >
        {t('menu.careers')}
      </a>,
      <a href={`https://getsetup6054.zendesk.com/hc`}>{t('nav.help')}</a>,
      <a
        href="https://www.getsetup.com/about-us/press"
        onClick={() => eventsTracker.handleOnClickAndTrackEvent(UIElementNames.LINK_SIDEBAR_MENU_PRESS)}
        target="_blank"
      >
        {t('menu.press')}
      </a>,
      <a href="https://www.getsetup.com/about-us/contact-us" target="_blank">
        {t('menu.partnerWithUs')}
      </a>,
      //Commented out until page is live
      // <Link href={`${BASE_URL}/careers`} onClick={() => eventsTracker.handleOnClickAndTrackEvent(UIElementNames.LINK_SIDEBAR_MENU_CAREERS)}>
      //     {t('menu.careers')}
      // </Link>,
    ]
    // if (!data?.currentUser?.authenticatedUser) {
    //   links.push(
    //     <Button
    //       size="lg"
    //       theme="secondary"
    //       label={t('cta.signUpFree')}
    //       href="/login"
    //       component="link"
    //       onClick={() => eventsTracker.handleOnClickAndTrackEvent(UIElementNames.BUTTON_SIDEBAR_MENU_SIGNUP)}
    //     />,
    //   )
    // } else {
    //   links.push(
    //     <Button
    //       size="md"
    //       theme="secondary"
    //       label="Logout"
    //       href="/logout"
    //       component="link"
    //       // Event tracking commented out until UIElementName added to names list;
    //       // onClick={() => eventsTracker.handleOnClickAndTrackEvent(UIElementNames.BUTTON_SIDEBAR_MENU_LOGOUT)}
    //     />,
    //   )
    // }

    return links
  }, [data, t])

  const policyLinks = useMemo(() => {
    return [
      <a
        href="https://www.getsetup.com/about-us/privacy-policy"
        onClick={() => eventsTracker.handleOnClickAndTrackEvent(UIElementNames.LINK_FOOTER_PRIVACY_POLICY)}
        target="_blank"
      >
        {t('menu.privacyPolicy')}
      </a>,
      <a
        href="https://www.getsetup.com/about-us/terms-of-service"
        onClick={() => eventsTracker.handleOnClickAndTrackEvent(UIElementNames.LINK_FOOTER_TERMS_CONDITIONS)}
        target="_blank"
      >
        {t('menu.termsConditions')}
      </a>,
    ]
  }, [menuLinks])

  const footerLinks = useMemo(() => {
    const links = [...menuLinks]
    if (isSmallDevice) {
      return [...links, ...policyLinks]
    }
    return links
  }, [menuLinks, policyLinks])

  return (
    <>
      <ZendeskMessagingWidget />
      <PageHeaderNext />
      <PageContent ceilingTargetNode={'.PageHeaderNext'}>
        <main className={`${contentClassName} ${disablePageContentBottomPadding ? '' : coreLayoutMain}`}>
          {children}
        </main>
      </PageContent>
      {footerVisible && (
        <PageFooter
          copyrightText={`GetSetUp. ${t('allRightsReserved')}`}
          links={footerLinks}
          policyLinks={isSmallDevice ? [] : policyLinks}
        />
      )}
      {/* TODO: HelpFloater temporarily replaced with FBMessenger, until we invest some effort
      into the content of HelpFloater and how it operates */}
      {/*
      {!menuActive && (
        <HelpFloater
          title={t('helpMenu.title')}
          label={t('nav.help')}
          items={[
            <Link href="/" passHref>
              <MenuOption sidePadding label={t('helpMenu.createAccount')} icon="person-plus" />
            </Link>,
            <Link href="/" passHref>
              <MenuOption sidePadding label={t('helpMenu.bookClass')} icon="play" />
            </Link>,
            <Link href="/" passHref>
              <MenuOption sidePadding label={t('helpMenu.cancelClass')} icon="cancel" />
            </Link>,
            <Link href="/" passHref>
              <MenuOption sidePadding label={t('helpMenu.chat')} icon="messenger" />
            </Link>,
          ]}
        />
      )}
      */}
    </>
  )
}

export default PageLayout

import IcoMoon, { IconNames } from '@/components/IcoMoon'
import classNames from 'classnames/bind'
import React from 'react'
import * as styles from './style'
const cx = classNames.bind(styles)

export interface Props {
  label?: string
  children?: JSX.Element
  error?: boolean
  errorMessages?: string[] | React.ReactNode[]
  id: string
  icon?: IconNames
  noBorder?: boolean
  className?: string
  iconSize?: number
}

const Field = ({
  icon,
  label,
  children,
  error,
  errorMessages,
  id,
  iconSize = 18,
  noBorder,
  className = '',
}: Props): JSX.Element => {
  const classes = cx({
    root: true,
    error,
    noBorder,
    hasIcon: !!icon,
    [className]: true,
  })
  return (
    <div className={classes}>
      {label && (
        <label data-testid="field-label" htmlFor={id}>
          {label}
        </label>
      )}
      <div className={styles.childWrapper} data-testid="input-wrapper">
        {children}
        {icon && <IcoMoon size={iconSize} className={styles.icon} icon={icon} />}
      </div>
      {errorMessages?.length && (
        <ul className={styles.errorList} data-testid="error-list">
          {errorMessages.map((item, i) => (
            <li data-testid="error-item" key={`${id}_err_${i}`}>
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
export default Field

// To hold menu & header in tandem, grouped and tethered to the page
export const wrapper = "w1nh2egm";
export const container = "cc675z2";
export const menuVisible = "m15vb5d6";
export const navigation = "n17k9wxi";
export const roundBottom = "r1r6f1xj";
export const logo = "l1wlqjqo";
export const phone = "pn07h8h";
export const endSection = "etdtjl5";
export const avatar = "a1eyef2x";
export const chevron = "ck9em3v";
export const searchContainer = "s1cmr38f";
export const searchMenu = "s2xh268";
export const searchSuggestion = "s1ust678";
export const outgoing = "ov5dmy0";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");
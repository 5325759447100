export const root = "r16vhfws";
export const outerCircleRight = "o103bg7i";
export const outerCircleTop = "o1ou7vkb";
export const outerCircleLeft = "o1kxs8md";
export const shadow = "s10tuerv";
export const imageContainer = "i1xr0tjl";
export const imageRightBorderRadius = "i1cj1and";
export const imageLeftBorderRadius = "i1wtfydr";
export const textContainer = "tmmteq8";
export const image = "i11xcp7s";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");
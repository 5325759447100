import { getConfig } from '@/lib/config'
import { eventsTracker } from '@/lib/eventsTracker'
import * as Sentry from '@sentry/nextjs'

const {
  publicRuntimeConfig: {
    graphqlApi: { url },
  },
} = getConfig()

export interface Props {
  classId?: string
  sessionId?: string
}
export async function makeBooking({ classId, sessionId }: Props): Promise<object | boolean> {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone // eg: 'Australia/Melbourne'
  const timezoneOffset = new Date().getTimezoneOffset() // eg: -600

  // Track booking event
  if (classId && sessionId) eventsTracker.bookingEvent({ classId, slotId: sessionId })
  try {
    const mutationBody = {
      query: `
          mutation createSessionBooking(
            $timezone: String!
            $timezoneOffset: Int!
            $sessionId: String!
          ) {
            createSessionBooking(
              timezone: $timezone
              timezoneOffset: $timezoneOffset
              sessionId: $sessionId
            )
          }
        `,
      variables: {
        timezone,
        timezoneOffset,
        sessionId,
      },
    }
    const response: any = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(mutationBody),
    })
    const parsedResponse = await response.json()
    if (parsedResponse.errors) throw new Error(JSON.stringify(parsedResponse.errors))

    return parsedResponse
  } catch (err) {
    // Log this error to sentry but dont panic.
    console.error('Failed to make booking from gsu-web', err)
    Sentry.captureException(err)

    return false
  }
}

import Badge, { BadgeSize, BadgeTheme } from '@/components/Badge'
import { rbp } from '@/constants/measured-scope'
import { useFeatureToggle } from '@/context/featureToggles'
import { useMediaQuery } from '@react-hookz/web'
import classNames from 'classnames/bind'
import * as styles from './style'
export type { BadgeSize as BadgesSize, BadgeTheme as BadgesTheme }

const cx = classNames.bind(styles)

export interface Props {
  className?: string
  compactBadges?: boolean
  overlappingBadges?: boolean
  inFocus?: boolean // Flag passed by parent when it has focus to trigger
  isCommunityLedClass?: boolean
  isEncore?: boolean
  isNotes?: boolean
  isVideo?: boolean
  size?: BadgeSize
  theme?: BadgeTheme
  rounded?: boolean
}

const Badges = ({
  className = '',
  compactBadges = false,
  overlappingBadges = false,
  isCommunityLedClass = false,
  isEncore = false,
  isNotes = false,
  size,
  theme = 'MINIMAL',
  inFocus = false,
  isVideo = false,
  rounded = false,
}: Props): JSX.Element | null => {
  const isXs = useMediaQuery(`only screen and (min-width : ${rbp.xxs})`, { initializeWithValue: false }) // for screen-width > 320
  const isMd = useMediaQuery(`only screen and (min-width : ${rbp.smallTablet})`, { initializeWithValue: false }) // for screen-width > 600
  const isLg = useMediaQuery(`only screen and (min-width : ${rbp.tablet})`, { initializeWithValue: false }) // for screen-width > 980
  const showSessionFeatureIcons = useFeatureToggle('showSessionFeatureIcons')
  const enableBadges = useFeatureToggle('exp_badges')
  const showEncoreBadging = useFeatureToggle('showEncoreBadging')

  if (!enableBadges && !showSessionFeatureIcons) return null

  //additional condition to check allow showSessionFeatureIcons only with overlappingbadges prop
  if (showSessionFeatureIcons && !overlappingBadges && !enableBadges) return null

  if (!isCommunityLedClass && !isEncore && !isNotes && !isVideo) return null

  // Calculate size when not provided
  let calculatedSize: BadgeSize = 'SM'
  if (!size) {
    if (isXs) calculatedSize = 'XS'
    if (isMd) calculatedSize = 'MD'
    if (isLg) calculatedSize = 'LG'
  }

  const wrapperClass = cx({
    root: true,
    [className]: true,
    [styles.compactBadges]: compactBadges,
  })
  const itemClass = cx({
    [styles.item]: true,
    [styles.overlappingBadges]: overlappingBadges,
  })

  return (
    <div className={wrapperClass}>
      {isCommunityLedClass && (
        <div className={itemClass}>
          <Badge
            compactBadges={compactBadges}
            inFocus={inFocus}
            size={size ?? calculatedSize}
            theme={theme}
            type="COMMUNITY"
            rounded={rounded}
          />
        </div>
      )}
      {isEncore && showEncoreBadging && (
        <div className={itemClass}>
          <Badge
            compactBadges={compactBadges}
            inFocus={inFocus}
            size={size ?? calculatedSize}
            theme={theme}
            type="ENCORE"
            rounded={rounded}
          />
        </div>
      )}
      {isNotes && (
        <div className={itemClass}>
          <Badge
            compactBadges={compactBadges}
            inFocus={inFocus}
            size={size ?? calculatedSize}
            theme={theme}
            type="CLASS_NOTES"
            rounded={rounded}
          />
        </div>
      )}
      {isVideo && (
        <div className={itemClass}>
          <Badge
            compactBadges={compactBadges}
            inFocus={inFocus}
            size={size ?? calculatedSize}
            theme={theme}
            type="VIDEO"
            rounded={rounded}
          />
        </div>
      )}
    </div>
  )
}
export default Badges

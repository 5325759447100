import Button from '@/components/Button'
import GsuLoader from '@/components/GsuLoader'
import Icon from '@/components/Icon'
import Layout, { Stack } from '@/components/Layout'
import SearchResultItem from '@/components/SearchResultItem'
import Text from '@/components/Text'
import { coreLayoutContainer, coreLayoutSection, rbp } from '@/constants/measured-scope'
import { useNavigation } from '@/context/navigation'
import { GeneratingPage, eventsTracker } from '@/lib/eventsTracker'
import { SearchPageRequest, SessionCard } from '@/shared/api-generated-types'
import { BadgesSize } from '@/widgets/Badges'
import { useMediaQuery } from '@react-hookz/web'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { gql, useQuery } from 'urql'
import * as styles from './style'

export interface Props {
  hasResetOption?: boolean
  searchQuery?: string | undefined
}

const QUERY_SEARCH = gql`
  query SearchPage($request: SearchPageRequest!) {
    page: searchPage(request: $request) {
      sections {
        id
        ... on SearchPageSection {
          pageInfo {
            totalItems
          }
          items {
            ... on SessionCard {
              title
              id
              imageUrl
              guideAvatarUrl
              guideByline
              start
              isCommunityLedClass
              isEncore
              livenessType
              classIsNew
              navigationAction {
                ... on NavigateToClassDetailAction {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

const SuggestionSearchNext = ({ hasResetOption, searchQuery = '' }: Props): JSX.Element => {
  const { t } = useTranslation('common')
  const QUERY_VARS: Partial<SearchPageRequest> = {
    query: searchQuery,
    maxItemsPerSection: 10,
  }
  const [{ data, fetching }] = useQuery({
    query: QUERY_SEARCH,
    variables: { request: QUERY_VARS },
    requestPolicy: 'network-only',
    pause: searchQuery === '',
  })
  const { dispatch } = useNavigation()

  // We only have the one section in Search page at the moment
  const resultData = data?.page?.sections[0] || {}
  const resultItems = resultData?.items || []
  const pageInfo = resultData?.pageInfo
  const totalItems = pageInfo?.totalItems || 0
  const hasMore = searchQuery !== '' && totalItems > resultItems.length

  // Badge sizes
  const badgesSize: BadgesSize = useMediaQuery(`only screen and (min-width : ${rbp.tablet})`, {
    initializeWithValue: false,
  })
    ? 'SM'
    : 'XS'
  // Compact badges?
  const compactBadges = Boolean(
    useMediaQuery(`only screen and (max-width : ${rbp.tablet})`, { initializeWithValue: false }),
  )

  function closeSearch(): void {
    dispatch({ type: 'SET_SEARCH_OPEN', payload: false })
  }

  const [searchButtonHref, setSearchButtonHref] = useState('')

  useEffect(() => {
    const url = new URL('/search', window.location.href)
    url.searchParams.set('query', searchQuery)
    setSearchButtonHref(url.toString())
  }, [])

  return (
    <Layout className={styles.root}>
      <Stack y>
        <section className={styles.resultsMeta}>
          <Text size="xxs" weight="sm" tag="div" className="ta-c fc-primary p-xs">
            {pageInfo && searchQuery !== ''
              ? t('search.displaySummary', { limit: resultItems.length, total: totalItems })
              : t('search.prompt')}
          </Text>
        </section>
        {fetching && (
          <section className="pt-xxl pb-md ta-c">
            <GsuLoader />
          </section>
        )}
        <section className={`${coreLayoutSection} relative`}>
          {!fetching && hasResetOption && (
            <span className={styles.close} onClick={closeSearch}>
              <Icon name="close" size={24} />
            </span>
          )}
          <div className={`${coreLayoutContainer}`}>
            {!!resultData?.items && searchQuery !== ''
              ? resultData.items.map(
                  (
                    {
                      title,
                      id,
                      imageUrl,
                      guideAvatarUrl,
                      guideByline,
                      navigationAction,
                      start,
                      isCommunityLedClass,
                      isEncore,
                      classIsNew,
                      livenessType,
                    }: Partial<SessionCard>,
                    index: number,
                  ) => {
                    const onClick: () => void = () => {
                      closeSearch()
                      eventsTracker.handleSearchOnClick(index + 1, GeneratingPage.SEARCH, window.location.pathname)
                    }
                    return (
                      <SearchResultItem
                        key={`suggestion_${id}`}
                        title={title}
                        imageUrl={imageUrl}
                        guideAvatarUrl={guideAvatarUrl}
                        subtitle={guideByline}
                        start={start}
                        badgesSize={badgesSize}
                        compactBadges={compactBadges}
                        isCommunityLedClass={isCommunityLedClass}
                        isEncore={isEncore}
                        classIsNew={classIsNew}
                        navigationAction={navigationAction}
                        onClick={onClick}
                        livenessType={livenessType}
                      />
                    )
                  },
                )
              : null}
            {hasMore ? (
              <div className={`${styles.viewAll} mt-lg`}>
                <Button
                  onClick={closeSearch}
                  size="sm"
                  theme="primaryOutline"
                  component="link"
                  label={t('search.viewAllAction', { totalItems })}
                  href={searchButtonHref}
                />
              </div>
            ) : null}
          </div>
        </section>
      </Stack>
    </Layout>
  )
}
export default SuggestionSearchNext

import IcoMoon, { IconNames } from '@/components/IcoMoon'
import Layout, { Stack } from '@/components/Layout'
import classNames from 'classnames/bind'
import Link from 'next/link'
import * as styles from './style'

const cx = classNames.bind(styles)

export interface Props {
  active?: boolean
  link: string
  label: string
  icon: IconNames
}

const BottomMenuItem = ({ active = false, link, icon, label }: Props): JSX.Element => {
  const classes = cx({
    root: true,
    active,
  })

  return (
    <Layout className={classes}>
      <Link href={link} className={classes} prefetch={false}>
        <Stack y alignItems="center" justifyContent="space-between">
          <IcoMoon icon={icon} size={24} />
          <span>{label}</span>
        </Stack>
      </Link>
    </Layout>
  )
}
export default BottomMenuItem

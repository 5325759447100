export const root = "r1jd9klj";
export const chevron = "c1agjvi3";
export const enter = "eo8e82c";
export const leave = "l9j57f1";
export const enterFromLeaveTo = "e1k8el6i";
export const enterToLeaveFrom = "eaaduhh";
export const panel = "p16wt2f3";
export const popoverButton = "p1tch44b";
export const navTitle = "npkebcv";
export const dropdown = "dqbomxx";

require("./styles.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./styles.ts");
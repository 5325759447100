import Button from '@/components/Button'
import GsuLoader from '@/components/GsuLoader'
import Layout, { Stack } from '@/components/Layout'
import ResultItem from '@/components/ResultItem'
import Text from '@/components/Text'
import { eventsTracker, GeneratingPage } from '@/lib/eventsTracker'
import { SearchPageRequest, SessionCard } from '@/shared/api-generated-types'
import { useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { gql, useQuery } from 'urql'
import * as styles from './style'

export interface Props {
  searchQuery?: string | undefined
}

const QUERY_SEARCH = gql`
  query SearchPage($request: SearchPageRequest!) {
    page: searchPage(request: $request) {
      sections {
        id
        ... on SearchPageSection {
          pageInfo {
            totalItems
          }
          items {
            ... on SessionCard {
              title
              id
              imageUrl
              guideByline
              start
              navigationAction {
                ... on NavigateToClassDetailAction {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`

const SuggestionSearch = ({ searchQuery = '' }: Props): JSX.Element => {
  const { t } = useTranslation('common')
  const QUERY_VARS: Partial<SearchPageRequest> = {
    query: searchQuery,
    maxItemsPerSection: 10,
  }
  const [{ data, fetching }] = useQuery({
    query: QUERY_SEARCH,
    variables: { request: QUERY_VARS },
    requestPolicy: 'network-only',
    pause: searchQuery === '',
  })

  // We only have the one section in Search page at the moment
  const resultData = data?.page?.sections[0] || {}
  const resultItems = resultData?.items || []
  const pageInfo = resultData?.pageInfo
  const totalItems = pageInfo?.totalItems || 0
  const hasMore = searchQuery !== '' && totalItems > resultItems.length

  const [searchButtonHref, setSearchButtonHref] = useState('')

  useEffect(() => {
    const url = new URL('/search', window.location.href)
    url.searchParams.set('query', searchQuery)
    setSearchButtonHref(url.toString())
  }, [])

  return (
    <div className={styles.root}>
      <Layout>
        <Stack y alignItems="center">
          <Text size="xxs" weight="md" tag="div" className="ta-c pl-sm pr-sm">
            {searchQuery
              ? t('search.searchActiveText', {
                  action: fetching ? t('search.searchingText') : t('search.searchText'),
                  query: searchQuery,
                })
              : t('search.prompt')}
            {pageInfo && searchQuery !== '' ? (
              <Text size="xxs" weight="sm" tag="div" className="pb-sm">
                {t('search.displaySummary', { limit: resultItems.length, total: totalItems })}
              </Text>
            ) : null}
          </Text>

          {fetching && (
            <div className="pt-lg pb-md">
              <GsuLoader />
            </div>
          )}

          {!!resultData?.items && searchQuery !== ''
            ? resultData.items.map(
                (
                  { title, id, imageUrl, guideByline, navigationAction, start }: Partial<SessionCard>,
                  index: number,
                ) => {
                  const onClick: () => void = () => {
                    eventsTracker.handleSearchOnClick(index + 1, GeneratingPage.SEARCH, window.location.pathname)
                  }
                  return (
                    <ResultItem
                      key={`suggestion_${id}`}
                      title={title}
                      imageUrl={imageUrl}
                      subtitle={guideByline}
                      start={start}
                      navigationAction={navigationAction}
                      onClick={onClick}
                    />
                  )
                },
              )
            : null}

          {hasMore ? (
            <Button
              size="sm"
              theme="primaryOutline"
              component="link"
              label={t('search.viewAllAction', { totalItems })}
              href={searchButtonHref}
            />
          ) : null}
        </Stack>
      </Layout>
    </div>
  )
}
export default SuggestionSearch

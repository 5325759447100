import { coreLayoutContainer, rbp } from '@/constants/measured-scope'
import { BottomNavigationActiveTab, useNavigation } from '@/context/navigation'
import PrimaryNavigation from '@/widgets/PrimaryNavigation'
import SecondaryNavigation from '@/widgets/SecondaryNavigation'
import SuggestionSearchNext from '@/widgets/SuggestionSearchNext'
import { useMediaQuery } from '@react-hookz/web'
import classNames from 'classnames/bind'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import * as styles from './style'

const cx = classNames.bind(styles)

const PageHeaderNext = (): JSX.Element => {
  const router = useRouter()
  const isLargeDevice = useMediaQuery(`only screen and (min-width : ${rbp.lg})`, { initializeWithValue: false })
  const {
    state: { searchBox },
  } = useNavigation()
  const headerClasses = cx({
    root: true,
    ['PageHeaderNext']: true, // used for dom height calc in widgets/PageContent
  })

  // Bottom Navigation Active tab
  const bottomNavigationActiveTab = useMemo<BottomNavigationActiveTab>(() => {
    switch (router.pathname.toLowerCase()) {
      case '/':
      case '/partner/[slug]':
      case '/saga/[...slug]':
      case '/exp-home':
      case '/partner-with-us':
        return 'home'
      case '/account-settings':
      case '/notes/[sessionid]':
      case '/notes':
        return 'profile'
      default:
        return 'learn'
    }
  }, [router.isReady])

  // Remove PageContent scrollbars
  useEffect(() => {
    // @ts-ignore
    document.querySelector('body').style.overflow = searchBox.open ? 'hidden' : 'auto'
  }, [searchBox.open])

  return (
    <>
      <nav className={`${headerClasses} px-0 px-xl-gte-lg px-xxl-gte-xxl`}>
        <div className={coreLayoutContainer}>
          <PrimaryNavigation bottomNavigationActiveTab={bottomNavigationActiveTab} />
          {/* Show secondary navigation either for large devices or when searchbox is not open on small devices */}
          {(isLargeDevice || (!searchBox.open && bottomNavigationActiveTab === 'learn')) && <SecondaryNavigation />}
        </div>
      </nav>
      {searchBox.open && (
        <div
          data-testid="search-suggestions"
          className={styles.searchSuggestion}
          style={{
            top: isLargeDevice ? '128px' : '56px',
            height: isLargeDevice ? 'calc(100vh - 128px)' : 'calc(100vh - 56px)',
          }}
        >
          <SuggestionSearchNext hasResetOption={isLargeDevice} searchQuery={searchBox.query} />
        </div>
      )}
    </>
  )
}
export default PageHeaderNext

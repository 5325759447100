export const root = "r1med921";
export const initials = "i11qtb1s";
export const outlined = "oqqn94t";
export const attention = "auegjlj";
export const border = "b1kchp6g";
export const xs = "xy7vra2";
export const sm = "s1q0yox5";
export const xxsmd = "x7iqd9z";
export const xsmd = "xwoznen";
export const md = "mxfnl3n";
export const lg = "l1p6gxjm";
export const xl = "x13a3fe4";
export const xxl = "x1mvn10z";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");
import Avatar from '@/components/Avatar'
import Button from '@/components/Button'
import DateDisplay from '@/components/DateDisplay'
import IcoMoon from '@/components/IcoMoon'
import { Stack } from '@/components/Layout'
import LivePulse from '@/components/LivePulse'
import SponsorLogo from '@/components/SponsorLogo'
import StatusBadge from '@/components/StatusBadge'
import Text from '@/components/Text'
import { formatJsonLd } from '@/compositions/MetaInfo'
import { rbp } from '@/constants/measured-scope'
import { useBooking } from '@/context/booking'
import { useFeatureToggle } from '@/context/featureToggles'
import { CalendarUrl, createCalendarUrl } from '@/lib/calendarUrl'
import { getTimeFromNow } from '@/lib/datetime'
import { lobbyUrl } from '@/lib/lobbyUrl'
import { makeBooking } from '@/lib/makeBooking'
import {
  JoinBookedSessionCardAction,
  LivenessType,
  SessionCard,
  SessionCardAccessDisplay,
  SessionSchema,
  Sponsor,
} from '@/shared/api-generated-types'
import { CardPrimaryAction } from '@/widgets/Action'
import Badges, { BadgesTheme } from '@/widgets/Badges'
import BookingSuccessModal from '@/widgets/CardViewClass/BookingSuccessModal'
import { GeneratingPage } from '@getsetup-io/event-producer'
import { useMediaQuery } from '@react-hookz/web'
import dayjs from 'dayjs'
import { useTranslation } from 'next-i18next'
import getConfig from 'next/config'
import router from 'next/router'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as styles from './style'

type PossibleString = string | null | undefined

export interface Props {
  dateOnSide?: boolean
  badgesTheme?: BadgesTheme
  canBookmark?: boolean
  canRemind?: boolean
  durationMinutes?: number
  end?: PossibleString
  footerContent?: React.ReactNode | null
  href?: PossibleString
  id?: PossibleString
  imageUrl?: PossibleString
  accessDisplay?: SessionCardAccessDisplay
  guideAvatarUrl?: PossibleString
  live?: boolean | undefined
  rating?: number | null
  start?: PossibleString
  title?: PossibleString
  feedItem?: boolean
  reactionCount?: number
  viewCount?: number
  commentCount?: number
  guideByline?: SessionCard['guideByline']
  navigationAction?: SessionCard['navigationAction']
  primaryAction?: SessionCard['primaryAction']
  secondaryAction?: SessionCard['secondaryAction']
  navigateToGuideAction?: SessionCard['navigateToGuideAction']
  fluid?: boolean
  wide?: boolean
  isSchedule?: boolean
  fixedAspectRatio?: boolean
  metaInfo?: {
    jsonLd?: SessionSchema
  }
  searchResultIndex?: number // 70e7b1d8-0cb9-4883-8927-1a69372cfac2
  generatingPage?: GeneratingPage
  isCommunityLedClass?: boolean
  isEncore?: boolean
  isNotes?: boolean
  upcomingSession?: SessionCard
  sessionId?: string
  actionText?: string
  feedbackText?: string
  zeroVerticalSpacing?: boolean
  hide?: boolean
  sponsor?: Sponsor
  livenessType?: LivenessType
}

const ListViewClass = ({ ...props }: Props): JSX.Element => {
  const showAltLiveLabels = useFeatureToggle('showAltLiveLabels')
  const showClassNotesFeedbackLinks = useFeatureToggle('class_notes_feedback_links')
  const {
    publicRuntimeConfig: {
      website: { url: BASE_URL },
    },
  } = getConfig()

  const [isLobbyButton, setIsLobbyButton] = useState<boolean>(
    props.primaryAction?.__typename === 'JoinBookedSessionCardAction' ||
      props.primaryAction?.__typename === 'JoinLiveSessionCardAction',
  )

  const { t } = useTranslation('classes')
  const { state: bookingState, dispatch: bookingDispatch } = useBooking()
  const [buttonLabel, setButtonLabel] = useState<string | undefined>(props.primaryAction?.label && t('slotCard.book'))
  const [inFocus, setInFocus] = useState<boolean>(false)
  const [isBooked, setIsBooked] = useState<boolean>(false)
  const [bookingFailed, setBookingFailed] = useState<boolean>(false)
  const [bookingSuccess, setBookingSuccess] = useState<boolean>(false)
  const [calendarUrl, setCalendarUrl] = useState<CalendarUrl>({
    googleCalendarUrl: '',
    yahooCalendarUrl: '',
    outlookCalendarUrl: '',
    iCalUrl: '',
  })

  const isDesktop = useMediaQuery(`only screen and (min-width : ${rbp.tablet})`)
  const isMobile = useMediaQuery(`only screen and (max-width : ${rbp.sm})`)
  const isSmallDevice = useMediaQuery(`only screen and (max-width : ${rbp.lg})`)
  const feedbackLink = lobbyUrl(`/session/${props.sessionId}/feedback?source=class_notes`)

  const iconSize = isMobile ? 14 : isSmallDevice ? 16 : 20

  const badgesTheme: BadgesTheme = isDesktop ? 'FULL_ON_FOCUS' : 'FULL'
  const data = props.upcomingSession ? props.upcomingSession : props
  // Determine if class is live based on input data
  // when 'live' is true, defaults to true when no
  // start and end time are provided, for the purpose
  // of programmatically setting the UI where needed
  const timeNow: number = +new Date()
  const isLive =
    data.start && data.end ? timeNow >= +new Date(data.start) && timeNow <= +new Date(data.end) : props.live
  const dateDisplay = useMemo(() => {
    if (!data.start) return null
    return props.dateOnSide ? (
      <DateDisplay
        dayWithCalendar={!props.dateOnSide}
        abbreviated={props.dateOnSide}
        multiline={props.dateOnSide}
        value={data.start}
        color="dark"
      />
    ) : (
      <>
        <Stack x>
          <IcoMoon icon={'calendar'} size={iconSize} className="mr-xxxs" />
          <Text size="xxxs" responsiveSize={{ sm: 'xxxs', lg: 'xs' }} weight="lg">
            {dayjs(props.start).format('ddd D MMM,')}&nbsp;
          </Text>
          <Text size="xxxs" responsiveSize={{ sm: 'xxxs', lg: 'xs' }} weight="sm">
            {dayjs(props.start).format('hh:mma')}
          </Text>
        </Stack>
      </>
    )
  }, [data.start])

  const liveTimeStart = useMemo(() => {
    return getTimeFromNow(data.start)
  }, [data.start])
  const iconUrl: string | undefined =
    data.accessDisplay?.__typename === 'LockedAccessDisplay' ? data.accessDisplay?.iconUrl : undefined
  const bookedPrimaryAction = {
    __typename: 'JoinBookedSessionCardAction',
    url: lobbyUrl(`/session/${props.sessionId}`),
    label: t('slotCard.enter'),
  } as JoinBookedSessionCardAction

  const handleBookingAction = (): void => {
    bookingDispatch({ type: 'FETCHING', payload: { sessionId: props.sessionId } })
    makeBooking({ sessionId: props.sessionId }).then((response) => {
      if (response) {
        bookingDispatch({ type: 'BOOKED', payload: { sessionId: props.sessionId } })
        if (props.start && props.title && props.end)
          setCalendarUrl(
            createCalendarUrl({ start: props.start, end: props.end, title: props.title, sessionId: props.sessionId }),
          )
        toggleBookingSuccess()
      } else bookingDispatch({ type: 'FAILED', payload: { sessionId: props.sessionId } })
    })
  }
  const primaryActionButton = useMemo(() => {
    if (!props.primaryAction?.label) return null

    return isLobbyButton ? (
      <CardPrimaryAction primaryAction={bookedPrimaryAction}>
        <Button size="sm" theme="secondary" label={bookedPrimaryAction.label} />
      </CardPrimaryAction>
    ) : (
      <CardPrimaryAction
        primaryAction={props.primaryAction}
        makeBooking={handleBookingAction}
        navigationAction={props.navigationAction}
      >
        <Button
          size="sm"
          theme={
            isLive ||
            props.primaryAction?.__typename === 'JoinBookedSessionCardAction' ||
            props.primaryAction?.__typename === 'JoinLiveSessionCardAction'
              ? 'secondary'
              : 'primary'
          }
          label={buttonLabel}
        />
      </CardPrimaryAction>
    )
  }, [isLive, props.primaryAction, props.navigationAction, buttonLabel])

  const toggleBookingSuccess = (): void => {
    setBookingSuccess(!bookingSuccess)
  }
  useEffect(() => {
    if (bookingState.fetching?.find((s) => s === props.sessionId)) {
      setButtonLabel(t('slotCard.booking'))
    } else if (bookingState.booked?.find((s) => s === props.sessionId)) {
      setButtonLabel(t('slotCard.booked'))
      setIsBooked(true)
    } else if (bookingState.failed?.find((s) => s === props.sessionId)) {
      setButtonLabel(t('slotCard.bookingFailed'))
      setBookingFailed(true)
    }
  }, [bookingState, props.sessionId, t])

  // After 1.5 seconds, lets show the next action for a user to take
  useEffect(() => {
    const timer = setTimeout(() => {
      if (isBooked) {
        setIsBooked(false)
        setButtonLabel(t('slotCard.enter'))
        setIsLobbyButton(true)
      }

      if (bookingFailed) {
        setBookingFailed(false)
        setButtonLabel(t('slotCard.book'))
      }
    }, 1500)
    return () => clearTimeout(timer)
  }, [isBooked, bookingFailed, t])

  const handleOnClick = useCallback(() => {
    // Checking if the URL is on our website or not. If it is, we can use next.js routing.
    if (props.navigationAction?.url)
      try {
        const newUrl = new URL(props.navigationAction.url)
        if (newUrl.href.startsWith(BASE_URL)) {
          router.push(props.navigationAction.url)
        } else {
          window.location.href = props.navigationAction.url
        }
      } catch (error) {
        window.location.href = props.navigationAction.url
      }
  }, [])

  return (
    <>
      {bookingSuccess ? (
        <BookingSuccessModal toggleBookingSuccess={toggleBookingSuccess} calendarUrl={calendarUrl} />
      ) : null}

      <div
        data-testid="card"
        onMouseEnter={() => setInFocus(true)}
        onMouseLeave={() => setInFocus(false)}
        onClick={handleOnClick}
        className={`${styles.root} ${props.hide ? styles.hide : ''} ${
          props.zeroVerticalSpacing ? '' : styles.verticalSpacing
        }`}
      >
        {props.dateOnSide && (
          <div className={styles.dateOnSide}>
            {isLive ? (
              <StatusBadge clearBg={!!props.dateOnSide} className={props.dateOnSide ? styles.live : 'flex-center'}>
                <LivePulse />
                <div>{showAltLiveLabels ? t('onNow') : t('live')}</div>
                {typeof props.start === 'string' ? <div className={styles.formatLiveTime}>{liveTimeStart}</div> : null}
              </StatusBadge>
            ) : null}

            {isLive ? null : <div className={styles.dateOnSideDisplay}>{dateDisplay}</div>}
          </div>
        )}
        <div className={styles.imageContainer}>
          {
            // eslint-disable-next-line @next/next/no-img-element
            <img
              alt={data.title ? data.title : ''}
              src={data.imageUrl ? data.imageUrl : '/images/image-placeholder-large.png'}
              className={styles.image}
            />
          }

          {iconUrl && (
            <>
              <div className={styles.overlay}></div>
              {
                // eslint-disable-next-line @next/next/no-img-element
                <img src={iconUrl} className={`${styles.iconImage}`} />
              }
            </>
          )}
          {props.dateOnSide ? null : (
            <Badges
              className={styles.badges}
              isCommunityLedClass={data.isCommunityLedClass}
              isEncore={data.isEncore}
              inFocus={false}
              isNotes={props.isNotes}
              size="SM"
            />
          )}
          {props?.sponsor?.logoUrl && <SponsorLogo image={props?.sponsor?.logoUrl} />}
        </div>

        <div className={styles.infoContainer}>
          <div className={styles.header}>
            {data.title && (
              <Text
                tag="h2"
                title={data.title}
                size="xxs"
                responsiveSize={{ sm: 'xs', lg: 'md' }}
                weight="lg"
                className={`${styles.title} ellipsis-1L`}
              >
                {data.title}
              </Text>
            )}
            <div className={styles.guideContainer}>
              {data.guideAvatarUrl && <Avatar className={styles.guideAvatar} size="xs" src={data.guideAvatarUrl} />}
              {data.guideByline && (
                <Text
                  tag="div"
                  title={data.guideByline}
                  size="xxxs"
                  responsiveSize={{ sm: 'xxxs', lg: 'xs' }}
                  weight="sm"
                  className={styles.guideByline}
                >
                  {data.guideByline}
                </Text>
              )}
              {props.dateOnSide ? (
                <Badges
                  className={styles.badgesSideView}
                  isCommunityLedClass={data.isCommunityLedClass}
                  isEncore={data.isEncore}
                  isVideo={data.livenessType === LivenessType.Live}
                  inFocus
                  size={'SM'}
                  rounded
                  overlappingBadges
                  theme={'MINIMAL'}
                />
              ) : null}
            </div>
          </div>

          <div className={styles.footer}>
            <div>
              {!props.dateOnSide &&
                (isLive ? (
                  <>
                    <div className={styles.liveContainer}>
                      <div className={styles.inline}>
                        <LivePulse />
                        <Text tag="div" size="xxs" weight="lg" className={styles.liveText}>
                          {showAltLiveLabels ? t('onNow') : t('live')}
                        </Text>
                      </div>
                      {typeof data.start === 'string' ? (
                        <div className={styles.formatLiveTime}>{liveTimeStart}</div>
                      ) : null}
                    </div>
                  </>
                ) : (
                  <div className={styles.dateContainer}>
                    {dateDisplay}
                    {!props.dateOnSide ? (
                      <Badges
                        className={styles.badgesSideView}
                        isCommunityLedClass={data.isCommunityLedClass}
                        isEncore={data.isEncore}
                        isVideo={data.livenessType === LivenessType.Live}
                        inFocus
                        size={'SM'}
                        rounded
                        overlappingBadges
                        theme={'MINIMAL'}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
            </div>
            {primaryActionButton}

            {(props.actionText || props.feedbackText) && (
              <Stack x alignItems="center" justifyContent="flex-end" spaceBetweenX="sm">
                {!isMobile && showClassNotesFeedbackLinks && props.feedbackText && (
                  <div
                    onClick={(event) => {
                      event.preventDefault()
                      window.open(feedbackLink, '_blank')
                    }}
                  >
                    <Text className={styles.actions} size="xxxs" responsiveSize={{ sm: 'xxs', lg: 'xs' }} weight="sb">
                      {props.feedbackText}
                    </Text>
                  </div>
                )}
                {props.actionText && (
                  <Text className={styles.actions} size="xxxs" responsiveSize={{ sm: 'xxs', lg: 'xs' }} weight="sb">
                    {props.actionText}
                  </Text>
                )}
              </Stack>
            )}
          </div>
        </div>
      </div>
      {props.metaInfo && props.metaInfo.jsonLd ? (
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: formatJsonLd(props.metaInfo.jsonLd) }} />
      ) : null}
    </>
  )
}
export default ListViewClass

import { rbp } from '@/constants/measured-scope'
import { useMediaQuery } from '@react-hookz/web'
import React, { useEffect, useState } from 'react'
import * as styles from './style'

export interface Props {
  children?: React.ReactNode | React.ReactNode[]
  ceilingTargetNode?: string
  floorTargetNode?: string
}

const PageContent = ({ children, ceilingTargetNode = '.pageHeader' }: Props): JSX.Element => {
  const isLargeDevice = useMediaQuery(`only screen and (min-width : ${rbp.desktop})`, { initializeWithValue: false })
  const isMediumDevice = useMediaQuery(`only screen and (min-width : ${rbp.smallTablet})`, {
    initializeWithValue: false,
  })
  const [ceiling, setCeiling] = useState<string>('0px')
  const [floor, setFloor] = useState<string>('0px')
  const computed = {
    paddingTop: ceiling,
    paddingBottom: floor,
    paddingLeft: 0,
  }

  function calculate(): void {
    let value = 280
    if (isMediumDevice) {
      value = 356
    }
    if (isLargeDevice) {
      value = 320
    }
    try {
      if (document) {
        setCeiling(`${document?.querySelector(ceilingTargetNode)?.clientHeight}px`)
        // No padding bottom for desktop
        setFloor(`${isMediumDevice ? value : 0}px`)
      }
    } catch (error) {
      console.log('Could not set upper and lower bounds for page content', { error })
    }
  }

  useEffect(() => {
    calculate()

    window.addEventListener('resize', calculate)

    return () => {
      window.removeEventListener('resize', calculate)
    }
  }, [isMediumDevice, isLargeDevice])

  return (
    <div id="page-content" className={styles.root} style={computed}>
      {children}
    </div>
  )
}
export default PageContent

import { ModalProvider } from '@/context/modal'
import {
  JoinBookedSessionCardAction,
  JoinLiveSessionCardAction,
  MustAuthenticateUserCardAction,
  NavigateToClassDetailAction,
  SessionCard,
  ViewClassDetailCardAction,
} from '@/shared/api-generated-types'
import DialogAction from '@/widgets/DialogAction'
import Link from 'next/link'
import React, { ReactElement } from 'react'

export const CardPrimaryAction = React.memo(function CardPrimaryAction({
  primaryAction,
  navigationAction,
  makeBooking,
  children,
}: {
  primaryAction: SessionCard['primaryAction']
  navigationAction?: SessionCard['navigationAction']
  makeBooking?: () => void
  children: ReactElement
}): ReactElement {
  const action = primaryAction?.__typename ?? undefined
  let actionButton: ReactElement | undefined = undefined
  // External links are not for the next/link component
  if (action === 'JoinBookedSessionCardAction' || action === 'JoinLiveSessionCardAction') {
    actionButton = React.cloneElement(children, {
      component: 'link',
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation()
      },
      href: (primaryAction as JoinBookedSessionCardAction | JoinLiveSessionCardAction).url,
    })
  } else if (action === 'MustAuthenticateUserCardAction') {
    actionButton = React.cloneElement(children, {
      component: 'a', // TODO: This might be able to be changed to use next/link
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation()
      },
      href: (primaryAction as MustAuthenticateUserCardAction).url,
    })
  } else if (action === 'ViewClassDetailCardAction') {
    actionButton = React.cloneElement(children, {
      component: 'link',
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation()
      },
      href: (primaryAction as ViewClassDetailCardAction).url,
    })
  } else if (action === 'BookSessionCardAction') {
    actionButton = React.cloneElement(children, {
      component: 'button',
      onClick: (e: React.MouseEvent) => {
        e.stopPropagation()
        makeBooking?.()
      },
    })
  } else if (navigationAction) {
    actionButton = (
      <Link href={(navigationAction as NavigateToClassDetailAction).url} passHref>
        {React.cloneElement(children, {
          component: 'link',
        })}
      </Link>
    )
  }

  if (primaryAction?.__typename === 'GetAccessSessionCardAction' && primaryAction?.dialogType) {
    return (
      <ModalProvider>
        <DialogAction action={primaryAction} />
      </ModalProvider>
    )
  }

  return <>{actionButton}</>
})

export const CardSecondaryAction = React.memo(function CardSecondaryAction({
  secondaryAction,
  cancelBooking,
  children,
}: {
  secondaryAction: SessionCard['secondaryAction']
  cancelBooking?: (sessionId: string) => void
  children: ReactElement
}): ReactElement {
  const action = secondaryAction?.__typename ?? undefined

  if (action === 'CancelSessionBookingCardAction')
    return React.cloneElement(children, {
      component: 'button',
      onClick: cancelBooking,
    })
  return children
})

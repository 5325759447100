import AuthCTA from '@/components/AuthCTA'
import Avatar from '@/components/Avatar'
import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Layout, { Stack } from '@/components/Layout'
import Text from '@/components/Text'
import { rbp } from '@/constants/measured-scope'
import { useAuth } from '@/context/auth'
import { eventsTracker, UIElementNames } from '@/lib/eventsTracker'
import Field from '@/widgets/Field'
import SuggestionSearch from '@/widgets/SuggestionSearch'
import UserCard from '@/widgets/UserCard'
import { useClickOutside, useMediaQuery } from '@react-hookz/web'
import classNames from 'classnames/bind'
import debounce from 'lodash/debounce'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import router, { useRouter } from 'next/router'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import * as styles from './style'
const cx = classNames.bind(styles)

export interface MenuInterface {
  open: () => void
  close: () => void
  toggle: () => void
  openCustomMenu: () => void
  closeCustomMenu: () => void
}

interface UseMenuRef extends MenuInterface {
  ref: React.RefObject<MenuInterface>
}

export const useMenuRef = (): UseMenuRef => {
  const menuRef = React.useRef<MenuInterface>(null)
  return {
    ref: menuRef,
    open: (): void => {
      menuRef.current?.open()
    },
    close: (): void => {
      menuRef.current?.close()
    },
    toggle: (): void => {
      menuRef.current?.toggle()
    },
    openCustomMenu: (): void => {
      menuRef.current?.openCustomMenu()
    },
    closeCustomMenu: (): void => {
      menuRef.current?.closeCustomMenu()
    },
  }
}

export interface Props {
  pageTitle?: string
  theme: 'light' | 'dark'
  onBack?: () => void
  searchVisible?: boolean
  roundBottom?: boolean
  phoneNumber: string
  onBell?: () => void
  logoImageUrl?: string
  menuOpen?: string
  tethered?: boolean
  menuLinks?: JSX.Element[]
  onMenuToggle?: (targetMenu: ToggleMenus) => void
  customMenu?: JSX.Element
}

export type ToggleMenus = 'nav' | 'search' | 'custom' | ''

const BackButton = ({ onBack }: Partial<Props>): JSX.Element | null =>
  onBack ? <Button size="sm" iconLeft="arrow-left" theme="clear" onClick={onBack} /> : null

const PageHeader: React.ForwardRefRenderFunction<MenuInterface, Props> = (
  {
    pageTitle,
    theme = 'light',
    onBack,
    searchVisible = true,
    roundBottom = false,
    onBell,
    phoneNumber,
    logoImageUrl,
    tethered = false,
    menuLinks,
    onMenuToggle,
    customMenu,
  },
  ref,
): JSX.Element => {
  const menuRef = useRef(null)
  const userPopoutRef = useRef(null)
  const [menuVisible, setMenuVisible] = useState<ToggleMenus>('')
  const handleNavOpen = (): void => setMenuVisible('nav')
  const handleNavClose = (): void => setMenuVisible('')
  const handleNavToggle = (): void => setMenuVisible((prev) => (prev !== 'nav' ? 'nav' : ''))
  const [userPopoutVisible, setUserPopoutVisible] = useState(false)
  const { route, query } = useRouter()
  const { t } = useTranslation('common')
  const {
    state: { user },
    dispatch: authDispatch,
  } = useAuth()
  const [searchQuery, setSearchQuery] = useState<string | undefined>(query?.query ? `${query.query}` : undefined)
  const isLargeDevice = useMediaQuery(`only screen and (min-width : ${rbp.desktop})`, { initializeWithValue: false })
  const searchInput = useRef<HTMLInputElement>(null)

  const toggleUserPopout = useCallback(() => {
    setUserPopoutVisible((s) => !s)
  }, [user])

  useClickOutside(menuRef, () => {
    if (isLargeDevice) {
      setMenuVisible('')
    }
  })

  useClickOutside(userPopoutRef, toggleUserPopout)

  useEffect(() => {
    if (onMenuToggle) onMenuToggle(menuVisible)
    // @ts-ignore
    document.querySelector('body').style.overflow = menuVisible ? 'hidden' : 'auto'
  }, [menuVisible])

  useImperativeHandle(
    ref,
    () => ({
      open: handleNavOpen,
      close: handleNavClose,
      toggle: handleNavToggle,
      openCustomMenu: (): void => setMenuVisible('custom'),
      closeCustomMenu: (): void => setMenuVisible(''),
    }),
    [],
  )
  const headerClasses = cx({
    root: true,
    dark: theme === 'dark',
    roundBottom,
    ['pageHeader']: true, // used for dom height calc in widgets/PageContent
  })
  const wrapperClasses = cx({
    menuVisible: !!menuVisible,
    wrapper: true,
    tethered,
  })
  const menuClasses = cx({
    ['pageMenu']: true,
    menu: true,
    searchMenu: menuVisible === 'search',
    navMenu: menuVisible === 'nav',
    customMenu: menuVisible === 'custom',
  })

  const _handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(event.target.value)

    // add searchEvent tracking here if needed onChange
  }
  const handleSearchInputChange = debounce(_handleSearchInputChange, 500)

  // Emits searchEvent when user submits search form or clicks away from search form
  const handleSearchBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
    eventsTracker.searchEvent(event.target.value)
  }

  // When query changes the search has been triggered, so hide the 'search menu' which shows
  // suggestions for the query and blur the input field to avoid leaving the user sitting inside the
  // field wondering why the search suggestions are not showing up. Suggestions will re-engage when
  // focus is triggered once more
  useEffect(() => {
    setMenuVisible('')
    searchInput?.current?.blur()
  }, [query])

  const submitSearch = (event: React.SyntheticEvent): void => {
    searchInput?.current?.blur()
    event.preventDefault()
    const target = event.target as typeof event.target & {
      query: { value: string }
    }
    if (!target?.query?.value) return
    router.push(`/search?query=${target.query.value}`)
  }

  return (
    <div className={wrapperClasses}>
      <header className={headerClasses}>
        <Layout spacing="sm" className={styles.headerContent}>
          <Stack y alignSelf="center">
            <Stack
              x
              alignItems="center"
              className={`${searchVisible ? 'pb-xxs-lte-desktop' : ''} ${styles.itemsContainer}`}
            >
              <Link
                href="/"
                className={styles.logo}
                onClick={() => eventsTracker.handleOnClickAndTrackEvent(UIElementNames.LINK_HEADER_LOGO)}
              >
                <img src={logoImageUrl} alt="GetSetUp logo" loading="lazy" />
              </Link>
              {searchVisible && (
                <Layout className={styles.searchContainer}>
                  <Stack x alignItems="center" justifyContent="space-evenly">
                    {menuVisible === 'search' && (
                      <div>
                        <BackButton onBack={() => setMenuVisible('')} />
                      </div>
                    )}
                    <form method="GET" action="/search" onSubmit={submitSearch} className={styles.searchForm}>
                      <Stack x className={`${pageTitle ? 'pb-sm' : ''}`}>
                        <Field id="search" icon="search" noBorder iconSize={20}>
                          <input
                            name="query"
                            id="search"
                            type="search"
                            className="pr-gte-desktop-sm"
                            onFocus={() => setMenuVisible('search')}
                            disabled={menuVisible === 'nav'}
                            onChange={handleSearchInputChange}
                            defaultValue={route.includes('search') ? searchQuery : undefined}
                            ref={searchInput}
                            placeholder={t('searchPlaceholder')}
                            onBlur={handleSearchBlur}
                          />
                        </Field>
                        {!!user && onBell && (
                          <Button theme="white" size="lg" onClick={onBell} iconLeft="bell" className="ml-sm" />
                        )}
                      </Stack>
                    </form>
                  </Stack>
                </Layout>
              )}
              <Layout>
                <Stack className={styles.endSection} x alignItems="center" justifyContent="flex-end" spaceBetweenX="sm">
                  {phoneNumber ? (
                    <div className={styles.phone}>
                      <Button
                        iconLeft="phone-support"
                        size={isLargeDevice ? 'lg' : 'sm'}
                        component="link"
                        theme="clear"
                        className={styles.phoneNumber}
                        href={`tel:${phoneNumber}`}
                        label={
                          <>
                            <strong className="fs-xs-gte-desktop">{`${
                              isLargeDevice ? t('callUs') : ''
                            } ${phoneNumber}`}</strong>
                          </>
                        }
                        onClick={() => eventsTracker.handleOnClickAndTrackEvent(UIElementNames.LINK_HEADER_CALL_US)}
                      />
                    </div>
                  ) : null}
                  {!!user ? (
                    <div className="hide-lte-desktop pl-sm-gte-desktop">
                      <UserCard
                        avatar={<Avatar title={user?.fullName} size="sm" />}
                        theme="tertiary70"
                        title={user?.firstName}
                        smooth
                        onClick={toggleUserPopout}
                        iconRight={
                          <Icon
                            className={styles.chevron}
                            name="chevron-right"
                          /> /* TODO: Needs to be chevron-down but we couldn't export from Figma */
                        }
                      />
                      {userPopoutVisible ? (
                        <div className={styles.userPopout}>
                          <nav ref={userPopoutRef}>
                            <ul>
                              <li>
                                <Link href="/account-settings">{t('cta.viewProfile')}</Link>
                              </li>
                            </ul>
                            <hr />
                            <Button
                              theme="secondary"
                              label="Log out"
                              onClick={() => authDispatch({ type: 'LOGOUT' })}
                            />
                          </nav>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <AuthCTA />
                  )}
                </Stack>
              </Layout>
            </Stack>

            {pageTitle ? (
              <div className={`${styles.pageTitle} ${!searchVisible ? 'pt-sm' : ''}`}>
                <div className={styles.backButton}>
                  <BackButton onBack={onBack} />
                </div>
                <Text className="fc-dark-grey" size="xs" weight="sm">
                  {pageTitle}
                </Text>
              </div>
            ) : null}
          </Stack>
        </Layout>
      </header>

      <CSSTransition in={!!menuVisible} timeout={300} unmountOnExit classNames="pageMenu">
        <nav className={menuClasses}>
          {menuVisible === 'nav' && (
            <div className={styles.menuItemContainer} ref={menuRef}>
              <Layout spacing="lg">
                <Stack y>
                  {!!user && (
                    <UserCard
                      avatar={<Avatar className={styles.avatar} size="sm" title={user?.fullName} />}
                      theme="white"
                      title={user?.fullName}
                      subtitle={'View Profile'}
                      href="/account-settings"
                    />
                  )}
                  <Layout spacing="md">
                    <Stack tag="ul" y>
                      <TransitionGroup component={null} in="true" appear>
                        {menuLinks?.length &&
                          menuLinks.map((link, i) => {
                            return (
                              <CSSTransition key={`menuItem_${i}`} timeout={150} classNames="menuItem">
                                <li className="menuItem">{link}</li>
                              </CSSTransition>
                            )
                          })}
                      </TransitionGroup>
                    </Stack>
                  </Layout>
                </Stack>
              </Layout>
            </div>
          )}
          {menuVisible === 'search' && (
            <div className={styles.searchSuggestion}>
              <SuggestionSearch searchQuery={searchQuery} />
            </div>
          )}
          {menuVisible === 'custom' && customMenu}
        </nav>
      </CSSTransition>
    </div>
  )
}
export default forwardRef(PageHeader)

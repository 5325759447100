import Lottie, { AnimationItem } from 'lottie-web'
import React, { useEffect, useImperativeHandle, useState } from 'react'

export type LottieImageHandlers = {
  playForward: () => void
  playReverse: () => void
}

export interface Props {
  autoplay?: boolean
  className?: string
  loop?: boolean
  name: string
  path: string
}

export const LottieImage = React.forwardRef<LottieImageHandlers, Props>(
  ({ autoplay = true, className, loop = false, name, path }, ref: React.Ref<any>): JSX.Element => {
    const [animationItem, setAnimationItem] = useState<AnimationItem>()
    const [divRef, setRef] = useState<HTMLDivElement | null>(null)

    useEffect(() => {
      if (!divRef) return
      const animationItem = Lottie.loadAnimation({
        name,
        container: divRef,
        renderer: 'canvas',
        loop,
        autoplay,
        path,
      })
      setAnimationItem(animationItem)

      // OnUmMount
      return () => {
        animationItem?.destroy()
      }
    }, [divRef])

    useImperativeHandle(ref, () => ({
      playForward: () => {
        animationItem?.setDirection(1)
        animationItem?.play()
      },
      playReverse: () => {
        animationItem?.setDirection(-1)
        animationItem?.play()
      },
    }))

    return <div className={className} ref={(newRef) => setRef(newRef)} />
  },
)

export default LottieImage

// Produce initials for a name
export const initials = (name: string | undefined): string => {
  if (!name || !name.trim().length) return '?'
  const split = name.split(' ')
  if (split.length === 1) {
    return split.shift()?.charAt(0)?.toUpperCase() || '?'
  }
  return `${split.shift()?.charAt(0)?.toUpperCase()}${split.pop()?.charAt(0)?.toUpperCase()}`
}

// Capitalise the first letter of every word in a string
export const titleCase = (str: string): string => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}

export const kebabCase = (str: string): string => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z ]/g, '')
    .replaceAll(' ', '-')
}

/** @jsxImportSource react */
import Icon from '@/components/Icon'
import Text from '@/components/Text'
import { isValidLocale, LocaleToDisplay, RedirectToLocale, storeLocaleCookie } from '@/lib/languages'
import { Popover, Transition } from '@headlessui/react'
import { useRouter } from 'next/router'
import { Fragment, useCallback, useState } from 'react'
import * as styles from './styles'

export interface Props {
  firstName?: string
  fullName?: string
}

const LanguagePicker = (): JSX.Element => {
  const router = useRouter()
  const [displayedCurrentLanguagePreference, setDisplayedCurrentLanguagePreference] = useState(
    LocaleToDisplay(router.locale),
  )

  const handleLanguageSelect = useCallback(
    (locale: string, displayedLocalTitle: string, close: () => void): void => {
      if (isValidLocale(locale) && locale !== router.locale) {
        setDisplayedCurrentLanguagePreference(displayedLocalTitle)
        storeLocaleCookie(locale)
        const { asPath, query } = router
        RedirectToLocale(asPath, query, locale)
      }
      close()
    },
    [router],
  )

  return (
    <Popover className={styles.root}>
      {({ open, close }) => (
        <>
          <Popover.Button className={styles.popoverButton}>
            <img style={{ width: '20px' }} src="/images/globe2.svg" loading="lazy" />
            <Text size="xs" weight="sb">
              {displayedCurrentLanguagePreference}
            </Text>
            <span className={styles.chevron}>
              <Icon size={10} name={open ? 'chevron-black-up' : 'chevron-black-down'} />
            </span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter={styles.enter}
            enterFrom={styles.enterFromLeaveTo}
            enterTo={styles.enterToLeaveFrom}
            leave={styles.leave}
            leaveFrom={styles.enterToLeaveFrom}
            leaveTo={styles.enterFromLeaveTo}
          >
            <Popover.Panel className={styles.panel}>
              <div className={styles.dropdown}>
                <nav>
                  <ul>
                    <li onClick={() => handleLanguageSelect('en-US', 'En', close)}>
                      <Text size="xs">English</Text>
                    </li>
                    <li onClick={() => handleLanguageSelect('hi-IN', 'हिंदी', close)}>
                      <Text size="xs">हिंदी</Text>
                    </li>
                    {/*
                    <li onClick={() => handleLanguageSelect('zh-CN', '普通話', close)}>
                      <Text size="xs">普通話</Text>
                    </li>
                    <li onClick={() => handleLanguageSelect('es-US', 'Es', close)}>
                      <Text size="xs">Español</Text>
                    </li>
                    */}
                  </ul>
                </nav>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
export default LanguagePicker

import { getConfig } from '@/lib/config'

const {
  publicRuntimeConfig: {
    lobby: { url: LOBBY_URL },
  },
} = getConfig()

export const lobbyUrl = (path = ''): string => {
  return `${LOBBY_URL}${path}`
}

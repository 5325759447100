export const root = "r1ll1eb3";
export const primary = "pm94kxa";
export const secondary = "s1gz7jwy";
export const tertiary70 = "t1gzz2mr";
export const tertiary = "t1vbclbf";
export const white = "wzh9guz";
export const black = "byxej71";
export const clear = "c180911c";
export const primaryNext = "ppb43yr";
export const title = "t1mcezm2";
export const content = "c1u2sx79";
export const smooth = "s1r8y94h";
export const padding = "p15ibaeu";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");
// To hold menu & header in tandem, grouped and tethered to the page
export const wrapper = "w6oqifq";
export const navMenu = "n7pekod";
export const topBar = "t15i9m2c";
export const menuButton = "muxmx4z";
export const menuItem = "m2txw7z";
export const headerContent = "hxigmeg";
export const roundBottom = "rlvh6gy";
export const logo = "l9wijlp";
export const endSection = "e12b0xxx";
export const avatar = "a1qquqn";
export const chevron = "c689unc";
export const searchWrapper = "s1uobh0e";
export const searchContainer = "s1bjumob";
export const searchIcon = "s1o3a648";
export const searchClose = "s1uq5mc8";
export const userPopout = "u147t2ml";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");
import Icon from '@/components/Icon'
import { useNavigation } from '@/context/navigation'
import { eventsTracker } from '@/lib/eventsTracker'
import Field from '@/widgets/Field'
import debounce from 'lodash/debounce'
import { useTranslation } from 'next-i18next'
import router, { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import * as styles from './style'

interface Props {
  hasResetOption?: boolean
  isSmallDevice?: boolean
  isLargeDevice?: boolean
}

const SearchBox = ({ hasResetOption, isSmallDevice, isLargeDevice }: Props): JSX.Element => {
  const { query } = useRouter()
  const { t } = useTranslation('common')
  const {
    state: { searchBox },
    dispatch,
  } = useNavigation()
  const searchForm = useRef<HTMLFormElement>(null)
  const searchInput = useRef<HTMLInputElement>(null)
  const [searchQuery, setSearchQuery] = useState<string | undefined>(
    query?.query ? `${query.query}` : searchBox?.query ? `${searchBox.query}` : undefined,
  )
  useEffect(() => {
    if (!searchBox.open) return
    searchInput?.current?.focus()
  }, [searchBox.open])

  const _handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const searchInput = event.target.value
    if (searchInput.length > 2) {
      setSearchQuery(searchInput)
      dispatch({ type: 'SET_SEARCH_QUERY', payload: searchInput })
      if (isLargeDevice) {
        searchInput
          ? dispatch({ type: 'SET_SEARCH_OPEN', payload: true })
          : dispatch({ type: 'SET_SEARCH_OPEN', payload: false })
      }
    }
    if (searchInput.length < 1) {
      resetSearch()
    }
    // add searchEvent tracking here if needed onChange
  }
  const handleSearchInputChange = debounce(_handleSearchInputChange, 500)

  // Emits searchEvent when user submits search form or clicks away from search form
  const handleSearchBlur = (event: React.ChangeEvent<HTMLInputElement>): void => {
    eventsTracker.searchEvent(event.target.value)
  }

  function submitSearch(event: React.SyntheticEvent): void {
    searchInput?.current?.blur()
    event.preventDefault()
    const target = event.target as typeof event.target & {
      query: { value: string }
    }
    if (!target?.query?.value) return
    router.push(`/search?query=${target.query.value}`)
  }

  function handleOnFocus(event: React.ChangeEvent<HTMLInputElement>): void {
    if (isSmallDevice) return
    if (event.target.value) dispatch({ type: 'SET_SEARCH_OPEN', payload: true })
  }

  function resetSearch(): void {
    setSearchQuery('')
    searchForm?.current?.reset()
    searchInput?.current?.focus()
    dispatch({ type: 'SET_SEARCH_OPEN', payload: false })
    dispatch({ type: 'SET_SEARCH_QUERY', payload: undefined })
  }

  return (
    <form ref={searchForm} method="GET" action="/search" onSubmit={submitSearch} className={styles.searchForm}>
      <Field className={styles.searchField} id="search" icon="search" noBorder iconSize={20}>
        <input
          ref={searchInput}
          className={styles.searchInput}
          defaultValue={searchQuery}
          id="search"
          name="query"
          onChange={handleSearchInputChange}
          onFocus={handleOnFocus}
          onBlur={handleSearchBlur}
          placeholder={t('searchPlaceholder')}
          type="search"
        />
      </Field>
      {hasResetOption && searchBox.query && (
        <span className={styles.reset} onClick={resetSearch}>
          <Icon name="close" size={8} />
        </span>
      )}
    </form>
  )
}

export default SearchBox

import Avatar from '@/components/Avatar'
import IcoMoon from '@/components/IcoMoon'
import { Stack } from '@/components/Layout'
import NewClassBadge from '@/components/NewClassBadge'
import Text from '@/components/Text'
import { useFeatureToggle } from '@/context/featureToggles'
import { LivenessType, SessionCard } from '@/shared/api-generated-types'
import Badges, { BadgesSize } from '@/widgets/Badges'
import ListViewClass from '@/widgets/ListViewClass'
import dayjs from 'dayjs'
import Link from 'next/link'
import * as styles from './style'

export interface Props {
  title?: string
  subtitle?: string
  imageUrl?: string
  guideAvatarUrl?: string
  start?: string
  badgesSize?: BadgesSize
  compactBadges?: boolean
  isCommunityLedClass?: boolean
  isEncore?: boolean
  classIsNew?: boolean
  navigationAction: SessionCard['navigationAction']
  onClick?: () => void
  livenessType?: LivenessType
}

const SearchResultItem = ({
  title,
  subtitle,
  imageUrl,
  guideAvatarUrl,
  navigationAction,
  start,
  compactBadges,
  isCommunityLedClass,
  isEncore,
  classIsNew,
  badgesSize,
  onClick,
  livenessType,
}: Props): JSX.Element => {
  const isNewHorizontalClassCard = useFeatureToggle('exp_horizontal_class_card')
  return (
    <Link href={navigationAction?.url ?? '/'} passHref legacyBehavior>
      {isNewHorizontalClassCard ? (
        <div onClick={onClick}>
          <ListViewClass
            title={title}
            guideAvatarUrl={guideAvatarUrl}
            guideByline={subtitle}
            imageUrl={imageUrl}
            start={start}
            isCommunityLedClass={isCommunityLedClass}
            isEncore={isEncore}
            navigationAction={navigationAction}
            livenessType={livenessType}
          />
        </div>
      ) : (
        <a className={styles.root} onClick={onClick}>
          {imageUrl ? (
            <Stack y className={styles.imageWrapper}>
              <div className={styles.image}>
                <img src={imageUrl} alt={title} loading="lazy" className="heroImage" />
                <Badges
                  className={styles.badges}
                  compactBadges={compactBadges}
                  isCommunityLedClass={isCommunityLedClass}
                  isEncore={isEncore}
                  size={badgesSize}
                />
                <NewClassBadge classIsNew={classIsNew} className={styles.newBadge} />
              </div>
            </Stack>
          ) : null}
          <Stack y className={styles.text}>
            {title ? (
              <Text
                size="xs"
                responsiveSize={{ sm: 'sm', lg: 'md' }}
                weight="lg"
                tag="strong"
                className={styles.truncate}
              >
                {title}
              </Text>
            ) : null}
            {subtitle ? (
              <Text
                size="xxs"
                responsiveSize={{ sm: 'xxs', lg: 'xs' }}
                weight="sm"
                className={`${styles.truncate} ${styles.subtitle}`}
              >
                <Avatar size="xs" src={guideAvatarUrl} className={styles.guidePhoto} />

                {subtitle}
              </Text>
            ) : null}
            {start ? (
              <Stack x justifyContent="flex-start">
                <Text size="xxs" responsiveSize={{ sm: 'xxs', lg: 'xs' }} weight="lg" className="flex-center">
                  <IcoMoon icon="calendar" size={18} className="mr-xxxs mr-xxs-gte-sm" />
                  {dayjs(start).format('MMMM DD, hh:mm A')}
                </Text>
                <Badges
                  className={styles.roundBadges}
                  isCommunityLedClass={isCommunityLedClass}
                  isEncore={isEncore}
                  isVideo={livenessType === LivenessType.Live}
                  inFocus
                  size={'SM'}
                  rounded
                  overlappingBadges
                  theme={'MINIMAL'}
                />
              </Stack>
            ) : null}
          </Stack>
        </a>
      )}
    </Link>
  )
}
export default SearchResultItem

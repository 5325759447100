import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Layout, { Stack } from '@/components/Layout'
import Text from '@/components/Text'
import { useTranslation } from 'next-i18next'
import * as styles from './style'

export interface Props {
  onDismiss?: () => void
  googleCalendarUrl: string | undefined
  iCalUrl: string | undefined
  yahooCalendarUrl: string | undefined
  outlookCalendarUrl: string | undefined
}

const AddToCalendar = ({
  googleCalendarUrl,
  iCalUrl,
  yahooCalendarUrl,
  outlookCalendarUrl,
  onDismiss,
}: Props): JSX.Element => {
  const { t } = useTranslation('classes')
  return (
    <Layout spacing="lg">
      <Stack y>
        <Button iconLeft="arrow-left" size="sm" theme="primaryMuted" onClick={onDismiss} />
        <Text size="xs" weight="md" className="ta-c">
          {t('addToCalendar.title')}
        </Text>
        <Stack x>
          <Stack y justifyContent="center">
            <a href={googleCalendarUrl} target="_blank">
              <Stack y alignItems="center">
                <img src="/images/google_calendar.png" className={styles.icon} loading="lazy" />
                <Text size="xxs" weight="sm" className="mt-sm">
                  {t('addToCalendar.google')}
                </Text>
              </Stack>
            </a>
            <a href={iCalUrl} target="_blank">
              <Stack y alignItems="center" className="mt-md">
                <img src="/images/ical.png" className={styles.icon} loading="lazy" />
                <Text size="xxs" weight="sm" className="mt-sm">
                  {t('addToCalendar.iCal')}
                </Text>
              </Stack>
            </a>
          </Stack>
          <Stack y justifyContent="center">
            <a href={outlookCalendarUrl} target="_blank">
              <Stack y alignItems="center">
                <Icon name="outlook-calendar" rawColors size={53} />
                <Text size="xxs" weight="sm" className="mt-sm">
                  {t('addToCalendar.outlook')}
                </Text>
              </Stack>
            </a>
            <a href={yahooCalendarUrl} target="_blank">
              <Stack y alignItems="center" className="mt-md">
                <Icon name="yahoo-calendar" rawColors size={53} />
                <Text size="xxs" weight="sm" className="mt-sm">
                  {t('addToCalendar.yahoo')}
                </Text>
              </Stack>
            </a>
          </Stack>
        </Stack>
      </Stack>
    </Layout>
  )
}
export default AddToCalendar
